#footer{
	padding: 30px 0 18px;
	position: relative;
	background-color: $ivory;
	font-family: $montserrat;
	box-shadow: inset 0 13px 20px -1px rgba(0,0,0,0.12); 

	@include media ('>=tablet') {
		padding: 30px 0 18px;
	}

	@include media ('>=widescreen') {
		padding: 30px 0 20px;
	}

	@include media ('>=xl') {
		padding: 37px 0 30px;
	}

	.container{
		@include media ('>=xl-phone') {
			padding: 0 50px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}

		@include media ('>=desktop') {
			padding: 0 96px 0 101px;
		}

		@include media ('>=xl') {
			padding: 0 26px 0 17px;
		}
	}

	.inner{
		padding: 40px 0 9px;
		// display: flex;
		// flex-wrap: wrap;
		// flex-direction: column;
		display: none;

		@include media ('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			width: calc(100% - 150px);
			padding: 24px 0 0 0;
		}

		@include media ('>=widescreen') {
			width: calc(100% - 200px);
		}

		@include media ('>=xl') {
			padding: 49px 0 20px;
			width: calc(100% - 241px);
			padding: 24px 0 0 0;
		}
	}

	.innerResponsive {
		display: none;

		@include media ('<=tablet') {
			display: block;
		}

		.btn-holder {
			display: flex;
			padding-right: 2rem;

			.link-box {
				display: flex;
				padding: .6rem;
				a {
					height: 3rem;
					margin: 0 1rem 0 0; 
					text-align: center;
					padding-top: .5rem;          
				}
			}     

			@include media ('<=xl-phone') {
				padding: 0 0 0 0;
			}
		}

		.footer-box {
			padding-left: 2rem;
		}

		.menu {
			margin-bottom: 0;
			margin-top: 1.5rem;
			@include media ('>tablet') {
				margin-left: 40px;
			}
			li {
				@include media ('<=tablet') {
					padding: 1rem 0 0 1rem;
				}
				@include media ('<=xl-phone') {
					font-size: 1rem;
					margin-left: 2rem;
					padding: .7rem 0 0 0;
				}
			} 
		}

		.brands {
			@include media ('<=xl-phone') {
				width: 100%;
				justify-content: space-evenly;
				margin-right: 2rem;
			}

			.liOtis {
				@include media ('>=tablet') {
					padding: 0 0 15px 0;
				}
			}
		}

		.contact-us-wrapper {
			width: 100%;
			flex-direction: column-reverse;

			.phones {
				margin-bottom: 1rem;

				li {
					font-size: 1rem;
					padding: .2rem 0;
				}
			}
		}


	}


	.logo-footer{
		width: 40%;

		@include media ('<tablet') {
			position: absolute;
			width: 35%;
			padding-top: 27px;
		}

		@include media ('>=tablet') {
			width: 150px;
		}

		@include media ('>=widescreen') {
			width: 200px;
		}

		@include media ('>=xl') {
			width: 241px;
		}

		img{
			@include media ('>=desktop') {
				margin-left: -16px;
				margin-top: 6px;
			}
		}
	}

	.menu{
		margin: 0 -10px;
		text-align: right;
		align-items: center;

		@include media ('>xl-phone') {
			font-size: 17px;
			margin-bottom: 30px;
		}

		@include media ('<tablet') {
			width: 60%;
		}

		@include media ('>=tablet') {
			padding-bottom: 20px;
			font-size: 13;
			text-align: center;
		}

		@include media ('>=1296px') {
			font-size: 13;
		}

		@include media ('>=desktop') {
			margin: 0 -18px;
			padding-bottom: 10px;
		}

		@include media ('>=xl') {
			margin: 0 -21px;
			padding-bottom: 20px;
		}

		a{
			color: $lagoon;

			&:hover{
				color: $crimson;
			}
		}

		li{
			line-height: 1.2;
			text-align: left;
			@include media ('<xl-phone') {
				padding: 0 10px 10px;
			}

			@include media ('>=desktop') {
				padding: 0 13px 10px;
			}

			@include media ('>=widescreen') {
				padding: 0 18px 10px;
			}

			@include media ('>=xl') {
				padding: 0 21px 10px;
			}

			@include media ('>=1600px') {
				padding: 0 25px 10px;
			}
		}
	}

	.btn-holder{
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;

		@include media ('<tablet') {
			flex-direction: column;
			margin-bottom: 20px;
		}

		@include media ('>=tablet') {
			width: 46%;
		}

		@include media ('>=desktop') {
			width: 50%;
		}

		@include media ('>=widescreen') {
			width: 39%;
		}
	}


	.main-button{
		min-width: 160px;
		padding: 6px 11px;

		@include media ('<tablet') {
			order: 2;
		}

		@include media ('>=tablet') {
			font-size: 12px;
			min-width: 125px;
			padding: 4px 11px;
		}

		@include media ('>=xl') {
			font-size: 15px;
			min-width: 156px;
			padding: 7px 11px;
		}
	}
}

.menu-holder{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	justify-content: flex-end;
}

.phones{
	@extend %listreset;

	display: flex;
	text-align: center;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 15px;

	@media only screen and (max-width: 820px) {
		margin-left: 0;
	}

	@include media ('<tablet') {
		order: 1;
		margin-bottom: 20px;
		font-size: 13px;
		margin-left: 0;
	}

	@include media ('>=tablet') {
		text-align: right;
	}

	@include media ('>=desktop') {
		margin-left: 50px;
	}

	@include media ('>=xl') {
		font-size: 16px;
	}

	li {
		margin-left: 10px;
		&:before {
			content: '|';
		}
		&:first-child {
			&:before {
				display: none;
			}
			@media only screen and (max-width: 820px) {
				margin-left: 0;

			}
		}
	}

	a{
		text-decoration: none;
		color: $dark;
		margin-left: 10px;

		&:hover{
			color: $crimson;
		}
	}

	p{
		margin-bottom: 0;
	}
}

.contact-us-wrapper{
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;

	@include media ('>=tablet') {
		justify-content: flex-end;
		width: 100%;
	}

	@include media ('>=desktop') {
		width: 100%;
	}

	@include media ('>=xl') {
		padding-left: 22px;
		width: 100%;
	}
}

.footer-box {
	display: flex; 
	justify-content: flex-end; 
	align-items: center;
	width: 100%;

	@include media ('<tablet') {
		max-width: 500px;
		margin: 0 auto;
	}

	@include media ('<=766px') {
		flex-direction: column-reverse
	}
}

.brands{
	@extend %listreset;

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 0 30px 0;

	@include media ('<tablet') {
		order: 2;
		justify-content: center;
		padding: 20px 0;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 950px) {
		flex-wrap: wrap;
		max-width: 480px;
	}

	@include media ('>=tablet') {
		width: 100%;
		padding-left: 20px;
	}

	@media screen and (min-width: 1400px) {
		width: 88%;
		padding-left: 20px;
		max-width: 100%;
	}

	@media screen and (min-width: 1400px) {
		width: 88%;
		padding-left: 20px;
	}

	.lbb {
		@include media ('<tablet') {
			flex: 0 1 50%;
			width: 50%;
			img {
				max-height: 80px;
			}
		}
		@media only screen and (max-width: 950px) {
			flex: 0 1 50%;
			width: 50%;
		}

		img {
			@include media ('>=tablet') {
				max-height: 80px;
				max-width: 170px;
			}

			@include media ('>=xl') {
				max-height: 80px;
				max-width: 170px;
			}
		}

	}

	.liOtis {
		@include media ('<tablet') {
			flex: 0 1 50%;
			width: 50%;
			img {
				max-height: 80px;
				max-width: 100%;
			}
		}
		@media only screen and (max-width: 950px) {
			flex: 0 1 50%;
			width: 50%;
		}

		img {
			@include media ('>=tablet') {
				max-height: 80px;
				max-width: 170px;
			}

			@include media ('>=xl') {
				max-height: 80px;
				max-width: 170px;
			}
		}

	}

	.lfb {
	}

	.pen {
	}

	li{
		text-align: center;
		padding: 0 20px;
		@include media ('<tablet') {
			padding: 20px;
			flex: 0 1 33%;
			width: 33%;
		}
		@media only screen and (max-width: 950px) {
			padding: 10px;
			flex: 0 1 33%;
			width: 33%;
		}

		img{
			max-height: 90px;
			max-width: 100%;
			height: auto;

			@include media ('<tablet') {
				max-height: 55px;
				max-width: 90px;
			}

			@include media ('>=tablet') {
				max-height: 70px;
				max-width: 120px;
			}

			@include media ('>=xl') {
				max-height: 70px;
				max-width: 120px;
			}

		}

	}



	a{
		@include animate(opacity);

		opacity: 1;

		&:hover{
			opacity: 0.7;
		}
	}

	img{
		max-height: 60px;

		@include media ('>=tablet') {
			max-height: 60px;
		}

		@include media ('>=xl') {
			max-height: 60px;
		}
	}
}

.copyright-part{
	text-transform: uppercase;
	padding: 22px 0 30px;
	color: $lagoon;
	font-weight: 700;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	position: relative;
	font-size: 14px;
	justify-content: center;

	@include media ('>=xl-phone') {
		justify-content: space-between;
		font-size: 20px;
	}

	@include media ('>=tablet') {
		padding: 12px 0;
		font-size: 13px;
	}

	@include media ('>=xl') {
		padding: 18px 0;
		font-size: 16px;
	}

	span{
		padding: 0 10px;

		@include media ('<tablet') {
			display: block;
		}

		@include media ('>=tablet') {
			max-width: 370px;
		}

		&.hark {
			font-size:0.7em;
		}
	}

	a{
		text-decoration: none;

		&:hover{
			color: $crimson;
		}
	}
}

.footer-wrapper{
	border-top: 3px solid $crimson;
	border-bottom: 3px solid $crimson;
	display: flex;
	flex-wrap: wrap;
}
