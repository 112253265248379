// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$gray2: #4e4e4e;
$gray3: #161616;
$black: #000;
$white: #fff;
$light-blue: #39f;
$lagoon: #003ca6;
$dark-lagoon: #001c4f;
$light-beige: #bf9372;
$beige: #a0857b;
$medium-beige: #c29574;
$dark-beige: #321306;
$lightskyblue: #a2bdca;
$deepskyblue: #a1bbc8;
$dark: #363636;
$dark2: #333;
$crimson: #a6192e;
$darkred: #791320;
$ivory: #ebebeb;
$lightgreen: #c6c8b0;
$darkcyan: #4e7b74;
$dark-green: #325230;


$base-font-sans-serif: 'freightsansbook', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$aislingregular: 'aislingregular', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$big_caslonmedium: 'big_caslonmedium', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;
$montserrat: 'Montserrat','freightsansbook', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$freighttext_medium: 'freighttext_medium', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$Myriad-Pro: 'Myriad-Pro', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$gillsans: 'gillsans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$gillsans_bold: 'gillsans_bold', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 18px;

// Body
$base-text-color: $dark;
$base-background-color: $white;
$font-size-base: 18px;
$line-height-base: 1.7;
$base-font-family: $base-font-sans-serif;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $lagoon;

// Buttons 
$btn-color: $lagoon;
$btn-background: transparent;
$btn-padding: 0.688em 0.625em;
$btn-font-size: inherit;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 35px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: $big_caslonmedium;
$headings-color: inherit !default;


$animation-speed: 0.3s;
