/* custom select styles */

.jcf-select {
	display: inline-block;
	vertical-align: top;
	position: relative;
	border: 1px solid #777;
	background: #fff;
	margin: 0 0 12px;
	min-width: 150px;
	height: 26px;
}

.jcf-select select {
	z-index: 1;
	left: 0;
	top: 0;
}

.jcf-select .jcf-select-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	font-size: 13px;
	line-height: 26px;
	margin: 0 35px 0 8px;
}

.jcf-select .jcf-select-opener {
	position: absolute;
	text-align: center;
	background: #aaa;
	width: 26px;
	bottom: 0;
	right: 0;
	top: 0;
}

body>.jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999;
}

body>.jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0px;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px;
}

.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto;
}

.jcf-select-drop .jcf-select-drop-content {
	border: 1px solid #f00;
}

/* multiple select styles */

.jcf-list-box {
	overflow: hidden;
	display: inline-block;
	border: 1px solid #b8c3c9;
	min-width: 200px;
	margin: 0 15px;
}

/* select options styles */

.jcf-list {
	display: inline-block;
	vertical-align: top;
	position: relative;
	background: #fff;
	line-height: 14px;
	font-size: 12px;
	width: 100%;
}

.jcf-list .jcf-list-content {
	vertical-align: top;
	display: inline-block;
	overflow: auto;
	width: 100%;
}

.jcf-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.jcf-list ul li {
	overflow: hidden;
	display: block;
}

.jcf-list .jcf-overflow {
	overflow: auto;
}

.jcf-list .jcf-option {
	overflow: hidden;
	cursor: default;
	display: block;
	padding: 5px 9px;
	color: #656565;
	height: 1%;
}

.jcf-list .jcf-disabled {
	background: #fff !important;
	color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
	background: #e6e6e6;
	color: #000;
}

.jcf-list .jcf-optgroup-caption {
	white-space: nowrap;
	font-weight: bold;
	display: block;
	padding: 5px 9px;
	cursor: default;
	color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
	padding-left: 30px;
}

/* common custom form elements styles */

.jcf-disabled {
	background: #ddd !important;
}

.jcf-focus,
.jcf-focus * {
	border-color: #f00 !important;
}