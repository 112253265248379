#header{
	@include animate(padding);

	background-color: $white;
	// background-color: red;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 15px 0;
	z-index: 5;
	font-family: $montserrat;

	@include media ('>=xl-phone') {
		padding: 23px 0 22px;
	}

	@include media ('>=tablet') {
		padding: 10px 0 0;
	}

	@include media ('>=desktop') {
		padding: 26px 0 0;
	}

	@include media ('>=xl') {
		padding: 34px 0 0;
	}
	
	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		width: 100%; 
		height: 100%;
		box-shadow: 0 0 22px rgba(0,0,0,0.5); 
		z-index: -1; 
	}

	&.fixed-position{
		@include media ('>=375px') {
			padding: 5px 0;
		}

		@include media ('>=tablet') {
			padding: 10px 0 0;
		}

		@include media ('>=desktop') {
			padding: 10px 0 0;
		}

		@include media ('>=widescreen') {
			padding: 20px 0 0;
		}

		.logo{
			z-index: 3;

			@include media ('>=375px') {
				width: 100px;
				top: 0;
			}

			@include media ('>=tablet') {
				width: 125px;
				top: -10px;
			}

			@include media ('>=widescreen') {
				width: 170px;
				top: -20px;
			}

			@include media ('>=xl') {
				width: auto;
			}

			img{
				@include media ('>=widescreen') {
					width: 100px;
				}
			}
		}

		.ch-header{
			width: 100%;
		}

		.menu{
			a{
				@include media ('>=tablet') {
					padding: 5px 0 12px;
				}

				@include media ('>=desktop') {
					padding: 5px 0 15px;
				}
			}
		}
	}

	.logo{
		@include animate(width padding top);

		width: 100px;
		padding: 15px 18px;
		display: inline-block;
		vertical-align: middle;
		box-shadow: 0px 0px 14px 8px rgba(34, 60, 80, 0.3);
		background-color: $white;
		z-index: 2;
		position: absolute;
		top: 0;
		text-align: center;

		@include media ('>=375px') {
			width: 120px;
		}

		@include media ('>=xl-phone') {
			width: 163px;
		}

		@include media ('>=tablet') {
			width: 150px;
			padding: 14px 25px;
			top: -10px;
		}

		@include media ('>=desktop') {
			top: -26px;
		}

		@include media ('>=widescreen') {
			padding: 25px 47px 20px;
			width: 218px;
		}

		@include media ('>=xl') {
			padding: 22px 52px 23px;
			top: -34px;
			width: auto;
		}

		img{
			@include animate(width);

			width: 98px;

			@include media ('>=desktop') {
				width: 164px;
			}
		}
	}

	.container{
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		@include media ('>=xl-phone') {
			padding: 0 33px 0 52px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
			position: relative;
		}

		@include media ('>=1296px') {
			padding: 0 82px 0 102px;
		}

		@include media ('>=xl') {
			padding: 0 15px;
		}
	}

  #sm-icon {
    margin-left: auto;
    z-index: 4;

    // @include media ('<phone') {
    //   margin-left: 4rem;
    // }

    // @include media ('<xl-phone')  {
    //   margin-left: 8rem;
    // }

    // @include media ('<tablet') {
    //   margin-left: 16rem;
    // }

    @include media ('>=tablet') {
      display: none;
    }
  }

	.menu{
		a{
			position: relative;

			@include media ('<tablet') {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		li{
			    text-align: center;
    line-height: 1.2;
			&.active{
				.plus-nav{
					&:before{ 
						transform: translate(-50%, -50%) rotate(90deg); 
					}

					&:after{ 
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}
		}
	}

	
	.slide{
		@extend %listreset;

		@include media ('<tablet') {
			padding: 20px 0 0 0;

			li{
				padding: 10px 0;
			}
		}

		@include media ('>=tablet') {
			display: none;
		}
	}

	.nav-wrapper{
		@include animate(opacity visibility);

		position: absolute;
		top: 0;
		overflow: hidden;
		right: 0;
		background-color: rgba($dark-lagoon, 0.9);
		height: 674px;
		width: 82%;
		padding: 80px 0;
		z-index: 1;
		opacity: 0;
		visibility: hidden;

		@include media ('>=xl-phone') {
			padding: 110px 0 50px;
		}

		@include media ('>=tablet') {
			padding: 0 0 0 150px;
			width: 100%;
			background-color: transparent;
			justify-content: flex-end;
			margin-left: auto;
			position: static;
			opacity: 1;
			visibility: visible;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			overflow: visible;
			height: auto;
			flex-direction: column;
		}

		@include media ('>=desktop') {
			padding: 0 0 0 210px;
		}

		@include media ('>=widescreen') {
			padding: 0 13px 0 280px;
		}

		.nav-active & {
			opacity: 1;
			visibility: visible;
		}
	}

	.nav{
		display: flex;
		flex-direction: column;
		overflow: auto;
		width: 100%;
		height: 100%;
		padding: 30px 10px;

		@include media ('>=375px') {
			padding: 30px 41px;
		}

		@include media ('>=tablet') {

			height: auto;
			padding: 0;
			overflow-x: hidden;
		}

		.nav-lang{
			justify-content: flex-end;
			padding: 20px 0;

			@include media ('>=xl-phone') {
				padding: 43px 0;
			}

			li{
				padding: 0 11.5px 11.5px;
			}

			@include media ('>=tablet') {
				display: flex;
			}
		}

		.lang-list{
			@include media ('>=tablet') {
				margin: 0 -7px;
			}

			@include media ('>=xl') {
				margin: 0 -10px;
			}
		}
	}

	.menu{
		text-align: right;

		@include media ('>=xl') {
			margin: 0 -22px;
		}

		a{
			@include animate(padding color);

			color: $white;
			display: block;

			@include media ('>=tablet') {
				color: $lagoon;
				padding: 5px 0 23px;
			}

			@include media ('>=xl') {
				padding: 7px 0 29px;
			}

			&:hover{
				color: $light-beige;

				@include media ('>=tablet') {
					color: $crimson;
				}

				&:before{
					opacity: 1;
				}
			}

			&.active{
				@include media ('>=tablet') {
					color: $crimson;
				}
			}
		}

		.submenu{
			&.bg-img{
				background-repeat: repeat;
				background-size: contain;
			}

			a{
				color: $crimson ;
				text-decoration: none;
				display: flex;
				align-items: center;
				padding: 0;

				&:hover{
					color: $lagoon;

					.img-holder{
						opacity: 0.7;
					}
				}
			}

		}
	}

    .contact-header.responsive-header{
      width: 100%;
      display: none;
      padding-left: 5rem;

      @include media ('<tablet') {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
        a {
        text-decoration: none;
      }
      
      .icon-row {
        display: flex;
      }

    }
   
 @include media ('<tablet') {
      .header-contact-button{
        font-size: .5rem;
        padding: 7px 0px 4px;
        margin-top: 0;
      }
    }
 
   .contact-header.ch-header{
			@include animate(padding);
      display: none;
      padding: 34px 0;
      text-align: center;
      color: $white;

    @include media ('>=tablet') {
      padding: 0 0 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
    }

    @include media ('>=desktop') {
      padding: 0 0 10px;
    }



      
    
  }

  .usa-can {
        filter: grayscale(100%);
        &:hover {
          filter: none;
          .dropdown-content {
            display: block;
       }
        }
      }
     
    .can-can {
        filter: grayscale(100%);
        &:hover {
          filter: none;
          .dropdown-content {
            display: block;
       }
        }
      }


	.lang-dropdown {
  position: relative;
  display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    color: darkblue;
    text-align: center;
    font-weight: 700;
    min-width: 100px;
    z-index: 100000000000000;
    border: 3px solid rgb(181, 38, 38);

    p {
      margin-bottom: 0;

       &:hover {
         background-color: rgb(208, 242, 255);
       }

    }    
    a {
       text-decoration: none;
    }

  } 
}

.nav-button{

	margin-left: 10px;
	position: relative;
	min-width: 80px !important;
	z-index: 4;
	height: 100%;
	padding: 5px 11px !important;



	@include media ('<xl-phone') {
		font-size: 10px;
	}

  @include media ('<tablet') {
    font-size: 13;
  }

	@include media ('>=xl-phone') {
		min-width: 145px !important;
	}

	@include media ('>=tablet') {
		display: none !important;
	}
}



.menu{
	@extend %listreset;

	font-weight: 800;
	font-size: 15px;

	@include media ('>=xl-phone') {
		font-size: 23px;
	}

	@include media ('>=tablet') {
		font-size: 14px;
		display: flex;
		// flex-wrap: wrap;
    text-align: center;
		justify-content: flex-end;
		margin: 0 -6px;
	}

	@include media ('>=desktop') {
		margin: 0 -12px;
		font-size: 16px;
	}

	@include media ('>=1296px') {
		margin: 0 -17px;
	}

	@include media ('>=xl') {
		font-size: 20px;
		margin: 0 -21px;
	}

	a{
		text-decoration: none;
		color: $lagoon;
	}

	>li{
		padding: 0 10px 27px;

		@include media ('>=tablet') {
			padding: 0 6px;
		}

		@include media ('>=desktop') {
			padding: 0 12px;
		}

		@include media ('>=1296px') {
			padding: 0 17px;
		}

		@include media ('>=xl') {
			padding: 0 21px;
		}
	}

	.hover{
		.submenu{
			@include media ('>=tablet') {
				left: auto;
				right: 15px;
			}

			@include media ('>=widescreen') {
				right: 32px;
			}
		}
	}
}

.nav-active{
	overflow: hidden;

	.nav-opener{
		span,
		&:before,
		&:after{
			background-color: $white;
		}
	}

	.nav-button{
		color: $white;
	}
}

.nav-opener {
	@include animate(width height);

	width: 56px;
	height: 46px;
	position: relative;
  top: -.5rem;
  right: .2rem;
	display: block;
  margin-left: 0;
	z-index: 2;



	@include media ('>=tablet') {
		display: none;
	}

	&:hover {
		opacity:.9;
	}

	.nav-active & {
		span {
			opacity: 0;
		}

		&:before,
		&:after {
			transform: rotate(45deg);
			top: 45%;
			left: 25%;
			right: 25%;
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	span,
	&:before,
	&:after {
		background-color: $lagoon;
		position: absolute;
		top: 46%;
		left: 25%;
		right: 25%;
		height: 4px;
		border-radius: 5px;
		transition: all .3s linear;

		@include media ('>=375px') {
			height: 8px;
		}
	}

	&:before,
	&:after {
		content:'';
		top:27%;
	}

	&:after {
		top: 65%;
	}
}

.plus-nav{
	top: 4px;
	position: absolute;
	width: 14px;
	height: 14px;
	display: inline-block;
	vertical-align: middle;
	right: -19px;

	@include media ('>=xl-phone') {
		top: 10px;
	}

	@include media ('>=tablet') {
		display: none;
	}

	&:before,
	&:after{
		content: '';
		position: absolute;
		background-color: $light-beige;
		border-radius: 3px;
	}

	&:before{
		@include animate(transform);

		top: 50%;
		transform: translate(-50%, -50%) rotate(0);
		left: 50%;
		width: 3px;
		height: 14px;
	}

	&:after{
		@include animate(transform);

		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		width: 14px;
		height: 3px;
	}
}

.accordion-nav{
	@include media ('<tablet') {
		position: relative;
		border-bottom: 2px solid $light-beige;
		padding-bottom: 13px;
		padding-right: 19px;
	}
}

.contact-header{
	.main-button{
		min-width: 75px;
		font-size: 15px;
		font-weight: 800;
		padding: 7px 11px 4px;
		margin: 0 10px;

		@include media ('>=tablet') {
	    // margin: 0 23px;
			margin: 0 0 0 23px;
			font-size: 12px;
			min-width: 125px;
			padding: 4px 11px;
		}

		@include media ('>=xl') {
	    // margin: 0 26px;
			margin: 0 0 0 26px;
			font-size: 15px;
			min-width: 156px;
			padding: 7px 11px 4px;
		}
	}
}

.submenu{
	@include animate(opacity visibility top);
	@extend %listreset;

	display: none;

	@include media ('>=tablet') {
		position: absolute;
		box-shadow: inset 0 26px 34px -23px rgba(0,0,0,0.35); 
		background-color: $gray2;
		font-family: $montserrat;
		color: $crimson;
		padding: 43px 35px 10px;
		top: 100%;
		right: 0;
		left: -9999px;
		letter-spacing: -0.3px;
		max-width: 600px;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	
	&:after{
		@include media ('>=tablet') {
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%; 
			height: 100%;
			box-shadow: 0px 7px 9px 0px rgba(0,0,0,0.36);
			z-index: -1; 
			left: 0;
			right: 0;
		}
	}

	@include media ('>=desktop') {
		max-width: 800px;
	}

	@include media ('>=widescreen') {
		max-width: 950px;
	}

	@include media ('>=xl') {
		max-width: 1100px;
	}

	li{
		padding: 0 5px 15px;

		@include media ('>=tablet') {
			width: 50%;
		}

		@include media ('>=desktop') {
			padding: 0 5px 22px;
			width: 33.33%;
		}
	}

	a{
		color: $crimson ;
		text-decoration: none;
		display: flex;
		align-items: center;

		&:hover{
			color: $lagoon;

			.img-holder{
				opacity: 0.7;
			}
		}
	}

	h2{
		font-size: 15px;
		width: calc(100% - 100px);
		padding-left: 10px;
		margin-bottom: 0;
		font-family: $montserrat;
		text-align: left;

		@include media ('>=xl') {
			padding-left: 15px;
			width: calc(100% - 136px);
			font-size: 20px;
		}
	}

	.img-holder{
		@include animate(opacity);

		width: 100px;
		height: 50px;
		opacity: 1;

		@include media ('>=desktop') {
			width: 100px;
			height: 50px;
		}

		@include media ('>=widescreen') {
			width: 136px;
			height: 67px;
		}
	}
}

