body{
	@include media ('<tablet') {
		line-height: 1.4;
	}
}

#main{
	margin-top: 0;
	// margin-top: 46px;

	// @include media ('>=xl-phone') {
	// 	margin-top: 80px;
	// }

	// @include media ('>=tablet') {
	// 	margin-top: 73px;
	// }

	// @include media ('>=desktop') {
	// 	margin-top: 89px;
	// }

	// @include media ('>=xl') {
	// 	margin-top: 120px;
	// }
}

#wrapper{
	position: relative;
	overflow: hidden;
}

.resize-active{
	*{
		transition: none !important;
	}
}

.container{
	max-width: 1405px;
	padding: 0 15px;
	margin: 0 auto;
}

a{
	@include animate(color background-color);
}

h1,.h1{
	line-height: 1;

	@include media ('>=desktop') {
		font-size: 65px;

		&.lfb {
			font-size: 45px;
    		line-height: 1.3;
		}

	}
}

h2,.h2{
	@include media ('>=desktop') {
		font-size: 32px;
	}
}

h3,.h3{
	@include media ('>=desktop') {
		font-size: 28px;
	}
}

h4,.h4{
	@include media ('>=desktop') {
		font-size: 25px;
	}
}

.bg-img{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.bg-repeat{
	background-size: contain;
	background-position: center;
	background-repeat: repeat;
}

.main-button{
	text-transform: uppercase;
	font-weight: 800;
	border: 2px solid $light-beige;
	text-decoration: none;
	min-width: 177px;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	padding: 7px 11px 8px;
	line-height: 1.7;
	font-family: $montserrat;

	&:hover{
		background-color: $light-beige;
		border-color: $light-beige;
		color: $white;
	}

	&.white-v{
		@include animate(background-color border-color);

		color: $white;
		border-color: $white;

		&:hover{
			border-color: $light-beige;
			background-color: $light-beige;
		}
	}
}

.custom-heading{
	text-align: center;
	line-height: 0.9;

	.subtitle{
		color: $lightskyblue;
		font-size: 40px;
		font-family: $aislingregular;
		font-weight: 400;
		max-width: 400px;
		color: $lagoon;

		@include media ('>=desktop') {
			font-size: 60px;
		}
	}

	.h1{
		color: $light-beige;
		margin-bottom: 15px;
		font-family: $big_caslonmedium;
		font-weight: 500;

		@include media ('>=desktop') {
			word-spacing: -16px;
			font-size: 65px;

			&.lfb {
				font-size: 39px;
				word-spacing: -10px;
			}

		}
	}
}

.intro-fl-des{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 100px 0;
	color: $white;
	font-family: $big_caslonmedium;

	@include media ('>=tablet') {
		padding: 150px 0;
	}

	h1{
		font-family: $big_caslonmedium;;
		letter-spacing: 2px;
		word-spacing: -2px;
		font-weight: 500;
	}

	.main-button{
		@include media ('<tablet') {
			font-size: 14px;
			min-width: 145px;
			font-weight: 700;
		}
	}
}

.intro{
	position: relative;;
	min-height: 450px;

	@include media ('>=xl-phone') {
		min-height: 565px;
	}

	@include media ('>=desktop') {
		min-height: 930px;
	}

	.main-button{
		letter-spacing: 0px;
		font-weight: 700;

		@include media ('<xl-phone') {
			padding: 5px 11px;
		}
	}

	h1{
		margin-bottom: 20px;

		@include media ('>=desktop') {
			margin-bottom: 39px;
		}
	}
}

.intro-home {
	padding-top:0;
	padding-bottom:0;

	video {
		display:block;
		width:100%;
		height:auto;
	}
}

.intro-style{
	text-align: center;
	letter-spacing: 1.63px;
	&.lfb {

		.container {
			@include media ('>=widescreen') {
				top: -150px;
			}

			img {
				@include media ('<tablet') {
					max-width: 200px;
				}
			}

		}

	}

  &.pennant {

    .container {
      @include media ('>=widescreen') {
        top: -200px;
      }
    }
  }

	&:before{
		content: '';
		position: absolute;
		top: -1px;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: $black;
		opacity: 0.33;
	}

	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		z-index: 1;
	}
}

.dec-down{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		right: 0;
		background-image: url("../images/decor1.png");
		background-position: bottom;
		background-size: cover;
		height: 60px;
		z-index: 1;
	}
}

.dec-up{
	position: relative;

	&:before{
		content: '';
		position: absolute;
		top: -2px;
		left: 0;
		right: 0;
		background-image: url("../images/decor1.png");
		background-position: top;
		background-size: cover;
		height: 60px;
		transform: rotate(180deg);
		z-index: 1;
	}
}

.family{
	position: relative;
	text-align: center;
	padding: 23px 0 32px;

	@include media ('>=tablet') {
		padding: 57px 0 76px;
	}

	.container{
		@include media ('>=phone') {
			padding: 0 44px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}
	}

	.text-holder{
		text-align: center;
		max-width: 940px;
		margin: 0 auto 40px;

		@include media ('>=desktop') {
			margin: 0 auto 55px;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}

		p{
			margin-bottom: 30px;


			@include media ('>=desktop') {
				margin-bottom: 54px;
				font-size: 1.5rem;
			}
		}
	}

	.bg-decor{
		position: absolute;
		left: 0;
		top: 260px;
		height: auto;
		width: 158px;

		@include media ('>=widescreen') {
			top: 135px;
			width: 246px;
		}
	}

	.h1{
		@include media ('<tablet') {
			margin-bottom: 9px;
		}

		@include media ('>=tablet') {
			letter-spacing: -1px;
		}
	}

	.subtitle{
		@include media ('<tablet') {
			font-size: 32px;
		}
	}

	.custom-heading{
		@include media ('<tablet') {
			line-height: 1;
		}
	}

	.main-button{
		@include media ('<tablet') {
			min-width: 155px;
		}
	}
}

.brand-list{
	@extend %listreset;

	display: flex;
	flex-wrap: wrap;

	@include media ('>=phone') {
		margin: 0 -15px;
	}

	@include media ('>=xl-phone') {
		padding: 0 65px;
	}

	@include media ('>=tablet') {
		padding: 0 15px;
	}

	@include media ('>=xl') {
		margin: 0 -43px;
	}

	li{
		width: 100%;
		padding-bottom: 15px;
		&:first-child,
		&:nth-child(2) {

			@include media ('>=tablet') {
				width: 50%;
			}

			@include media ('>=xl') {
				padding: 0 21px 5px;
				margin-bottom: 42px;
			}

			.card-holder {
				padding-bottom: 55%;
			}

		}

		@include media ('>=xl-phone') {
			padding: 0 15px 33px;
		}

		@include media ('>=tablet') {
			width: 33.33%;
		}

		@include media ('>=xl') {
			padding: 0 21px 5px;
			margin-bottom: 42px;
		}
	}

	.inner{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img{
			@include animate(transform);

			max-height: 190px;
		}
	}


}

.card-holder{
	box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.2);
	padding-bottom: 70%;
	position: relative;
	display: block;

	&:hover{
		.inner{
			img{
				@include media ('>=tablet') {
					transform: scale(0.8);
				}

				@include media ('>=desktop') {
					transform: scale(1.3)
				}
			}
		}
	}
}

.foods{
	padding: 67px 0 72px;
	background-repeat: repeat;
	text-align: center;
	position: relative;

	@include media ('>=tablet') {
		padding: 93px 0 72px;
	}

	.subtitle{
		color: $light-beige;

		@include media ('<tablet') {
			font-size: 34px;
		}
	}

	.h1{
		color: $white;
		margin-bottom: 29px;
	}

	.white-decor{
		position: absolute;
		left: 0;
		right: 0;
		height: 505px;
		background-color: $white;
		bottom: -502px;

		@include media ('>=desktop') {
			bottom: -8%;
		}

		&:after{
			bottom: 99%;
		}
	}
}

.bg-decor-top{
	position: absolute;
	top: 98px;
	right: -51px;
	width: 178px;
	height: 150px;

	@include media ('>=tablet') {
		width: 250px;
	}

	@include media ('>=desktop') {
		right: 0;
		top: 130px;
		width: 290px;
		height: 175px;
	}

	@include media ('>=widescreen') {
		right: 36px;
		top: 152px;
	}
}

.bg-decor-bottom{
	position: absolute;
	bottom: 323px;
	left: -166px;
	width: 335px;
	height: 150px;

	@include media ('>=desktop') {
		left: 0;
		bottom: 130px;
		width: 493px;
		height: 256px;
	}

	@include media ('>=widescreen') {
		left: 172px;
		bottom: 490px;
	}

	@include media ('>=xl') {
		left: 100px;
	}

	@include media ('>=1600px') {
		left: 20px;
	}
}

.gallery-wrapper{
	padding: 0 7px 26px;

	@include media ('>=xl-phone') {
		padding: 0 37px 27px;
	}

	@include media ('>=desktop') {
		padding: 0 37px 37px;
	}
}

.gallery-slider{

	.slick-list{
		position: relative;
		z-index: 1;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-image: url("../images/frame.png");
			background-position: center;
			background-size: 100% 100%;
			z-index: 1;
			box-shadow: inset 0 0 20px 4px rgba($dark-lagoon, 1);

			@include media ('>=tablet') {
				box-shadow: inset 0 0 20px 10px rgba($dark-lagoon, 1);
			}
		}

		.image-holder{
			padding-bottom: 10%;

			@include media ('>=xl-phone') {
				padding-bottom: 8.1%;
			}
		}
	}
}

.food-wrapper{
	@include media ('>=xl-phone') {
		margin: 0 -60px;
	}

	@include media ('>=tablet') {
		margin: auto;
	}

	.main-button{
		position: relative;
		z-index: 1;
		color: $white;

		@include media ('>=desktop') {
			color: $lagoon;
		}

		&:hover{
			color: $white;
		}
	}
}

.food-carousel{
	z-index: 2;
	position: relative;
	padding: 0 60px;
	margin-bottom: 25px;

	@include media ('>=xl-phone') {
		padding: 0;
		padding: 0 40px;
	}

	@include media ('>=tablet') {
		padding: 0 60px;
	}

	@include media ('>=desktop') {
		padding: 0 65px;
		margin-bottom: 36px;
	}

	@include media ('>=widescreen') {
		padding: 0 77px;
	}

	&:before{
		@include media ('>=xl-phone') {
			content: '';
			position: absolute;
			background: linear-gradient(90deg, rgba(255,255,255,0) 20%, rgba($dark-lagoon, 1) 92%);
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			width: 155px;
		}

		@include media ('>=tablet') {
			display: none;
		}
	}

	&:after{
		@include media ('>=xl-phone') {
			content: '';
			position: absolute;
			background: linear-gradient(-90deg, rgba(255,255,255,0) 20%, rgba($dark-lagoon, 1) 92%);
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			width: 155px;
		}

		@include media ('>=tablet') {
			display: none;
		}
	}

	.slick-list{
		margin: 0 -10px;

		@include media ('>=desktop') {
			margin: 0 -16px;
		}
	}

	.wrapper{
		padding: 0 10px 10px;
		height: auto !important;

		@include media ('>=desktop') {
			padding: 0 16px 16px;
		}

		a{
			text-decoration: none;
			display: block;
			height: 100%;
			background-color: $deepskyblue;

			&:hover{

				.text-holder{
					color: $white;
				}
			}

			.text-holder{
				@include animate(color);
			}

			h3{
				font-weight: 600;
				letter-spacing: 1px;
				word-spacing: -8px;
			}
		}
	}

	.slick-track{
		display: flex;
	}
}

.slick-prev{
	left: 0;
	background-image: url("../images/arrow-prev.png");
	background-size: 100%;
}

.slick-next{
	right: 0;
	background-image: url("../images/arrow-next.png");
	background-size: 100%;
}

.slick-arrow{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 0;
	width: 35px;
	height: 35px;
	z-index: 4;
	background-color: transparent;
	border: 0;

	@include media ('>=desktop') {
		width: 49px;
		height: 49px;
	}
}

.card{
	@include animate(opacity);

	box-shadow: 0px 0px 15px 1px rgba(34, 60, 80, 0.5);
	opacity: 1;
	font-family: $big_caslonmedium;

	.text-holder{
		color: $dark2;
		background-color: $deepskyblue;
		padding: 13px 6px 8px;
		line-height: 1;
		text-align: center;

		@include media ('>=xl-phone') {
			padding: 13px 6px 8px 16px;
		}

		@include media ('>=tablet') {
			padding: 13px 15px 8px;
		}

		@include media ('>=desktop') {
			padding: 20px 15px 12px 17px;
		}

		h3{
			@include media ('<xl') {
				font-size: 18px;
			}
		}
	}

	.img-wrapper{
		padding-bottom: 83%;

		@include media ('>=desktop') {
			padding-bottom: 85%;
		}
	}
}

.who{
	padding: 31px 0;

	@include media ('>=tablet') {
		padding: 100px 0;
	}

	@include media ('>=desktop') {
		padding: 202px 0 86px;
	}

	@include media ('>=widescreen') {
		background-position: 100% -20%;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: linear-gradient(to top, rgba(255, 255, 255, 0) 84%, $white 96%);
	}

	.container{
		display: flex;
		flex-wrap: wrap;
	}

	.subtitle{
		color: $crimson;
	}

	.h1{
		margin-bottom: 15px;

		@include media ('>=xl-phone') {
			margin-bottom: 36px;
			word-spacing: -12px;
		}

		@include media ('>=desktop') {
			margin-bottom: 20px;
		}
	}

	.custom-heading{
		padding-bottom: 40px;
		padding-top: 4px;
		position: relative;
		width: 100%;
		line-height: 1;

		@include media ('>=xl-phone') {
			width: 50%;
			text-align: left;
			padding-left: 35px;
		}

		@include media ('>=tablet') {
			width: 40%;
		}

		@include media ('>=desktop') {
			width: 50%;
			padding: 148px 0 70px;
			text-align: left;
		}

		@include media ('>=widescreen') {
			width: 52%;
		}

		@include media ('<tablet') {
			padding-bottom: 0;

			> div {
				transform: none !important;
			}

		}

	}

	.title{
		position: absolute;
		bottom: -6px;
		left: 16px;

		@include media ('>=tablet') {
			left: 39px;
			bottom: 0;
		}

		h2{
			margin-bottom: 22px;
			word-spacing: -3px;

			@include media ('<desktop') {
				font-size: 20.5px;
			}
		}
	}

	.image-wrapper{
		min-height: 192px;
		width: 192px;
		opacity: 1;
		box-shadow: 0px 0px 18px 1px rgba(34, 60, 80, 0.3);

		@include media ('>=desktop') {
			width: 300px;
			min-height: 300px;
		}
	}
}

.list-for-more{
	@extend %listreset;

	margin: 34px auto -15px;
	width: 100%;
	font-family: $big_caslonmedium;

	@include media ('>=xl-phone') {
		width: 37%;
		margin: 34px auto -100px;
	}

	@include media ('>=tablet') {
		width: 60%;
		margin: 0 auto 30px;
	}

	@include media ('>=desktop') {
		width: 50%;
		margin-bottom: -6%;
	}

	@include media ('>=widescreen') {
		width: 47%;
	}

	li{
		position: relative;
		margin: 0 auto 15px;
		width: fit-content;

		@include media ('>=tablet') {
			padding: 0 15px;
			margin: initial;
		}

		&:first-child{
			z-index: 3;
		}

		&:nth-child(3n+2){
			z-index: 2;
			top: -35px;
			right: 46px;

			@include media ('>=xl-phone') {
				top: -62px;
				right: 148px;
			}

			@include media ('>=tablet') {
				top: -79px;
				right: -160px;
			}

			@include media ('>=widescreen') {
				right: -248px;
				top: -64px;
			}
		}

		&:nth-child(3n+3){
			top: -72px;
			right: -20px;

			@include media ('>=xl-phone') {
				top: -123px;
				right: 62px;
			}

			@include media ('>=tablet') {
				top: -120px;
				right: -49px;
			}

			@include media ('>=widescreen') {
				top: -103px;
				right: -64px;
			}
		}

		a{
			@include animate(opacity);

			opacity: 1;
			display: block;

			&:hover{
				opacity: 0.7;
			}
		}
	}

	.title{
		color: $white;
	}
}

.careers{
	padding: 33px 0 78px;

	@include media ('>=xl-phone') {
		padding: 33px 0 68px;
	}

	@include media ('>=tablet') {
		padding: 34px 0 81px;
	}

	@include media ('>=desktop') {
		padding: 58px 0 73px;
	}

	.subtitle{
		color: $lagoon;
	}

	h3{
		font-size: 30px;
	}

	h4{
		font-size: 20px;
	}

	.container{
		@include media ('>=xl-phone') {
			padding: 0 52px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}
	}
}

.careers{
	.text-wrapper{
		@include media ('>=tablet') {
			width: 50%;
		}
	}

	.custom-heading{
		text-align: left;

		@include media ('>=tablet') {
			padding-left: 37px;
		}

		@include media ('>=desktop') {
			text-align: center;
		}

		@include media ('>=widescreen') {
			margin-bottom: 52px;
			padding-left: 0;
		}

		.h1{
			@include media ('>=xl-phone') {
				margin-bottom: 6px;
			}

			@include media ('>=tablet') {
				margin-bottom: 15px;
			}
		}
	}

	.text-holder{
		max-width: 500px;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			top: 48%;
			background-image: url("../images/img10.jpg");
			background-repeat: no-repeat;
			height: 100%;
			width: 100%;
			opacity: 0.3;
			z-index: -1;
		}
	}

	.image-holder{
		position: relative;

		&:after{
			content: '';
			position: absolute;
			top: 48%;
			background-image: url("../images/bg-decor2.png");
			background-repeat: no-repeat;
			height: 100%;
			width: 100%;
			opacity: 0.3;
			z-index: -1;

			@include media ('>=desktop') {
				opacity: 1;
			}
		}
	}
}

.intro-brands{
	color: $white;
	min-height: 370px;

	@include media ('>=xl-phone') {
		min-height: 561px;
	}

	@include media ('>=tablet') {
		min-height: 597px;
	}
}

.brands-info{
	padding: 50px 0;
	position: relative;
	background-size: contain;
	background-repeat: repeat;

	@include media ('>=xl-phone') {
		padding: 44px 0 155px;
	}

	@include media ('>=tablet') {
		padding: 44px 0 241px;
	}

	.container{

		@include media ('>=xl-phone') {
			padding: 0 82px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}
	}

	.text-holder{
		text-align: center;
		margin: 0 auto 60px;

		@include media ('>=tablet') {
			max-width: 800px;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}
	}

	.bg-decor-info,
	.bg-decor-info2{
		position: absolute;

		&.bg-decor-info{
			width: 149px;
			height: auto;
			right: -3%;
			top: 12.4%;

			@include media ('>=tablet') {
				width: 247px;
				right: -3%;
				top: 30.4%;
			}
		}

		&.bg-decor-info2{
			width: 188px;
			height: auto;
			left: 0%;
			top: 85%;

			@include media ('>=tablet') {
				width: 230px;
				top: 68%;
			}
		}
	}
}

.brands-cards{
	@extend %listreset;

	display: flex;
	flex-wrap: wrap;
	font-family: $montserrat;
	color: $white;
	z-index: 1;
	position: relative;

	@include media ('>=tablet') {
		margin: 0 -10px;
	}

	@include media ('>=desktop') {
		margin: 0 -20px;
	}

	li{
		width: 100%;
		padding-bottom: 20px;
		&:first-child,
		&:nth-child(2){

			@include media ('>=tablet') {
				flex: 0 1 50%;
				width: 50%;
				padding-bottom: 42px;

							.brand-card {

				.image-holder{
					padding-bottom: 53%;
					position: relative;
				}

			}

			}

		}

		@include media ('>=xl-phone') {
			padding-bottom: 75px;
		}

		@include media ('>=tablet') {
			width: 33.33%;
			padding: 0 10px 20px;
		}

		@include media ('>=desktop') {
			padding: 0 20px 20px;
		}

	}
}

.brand-card{
	background-color: $dark-lagoon;
	position: relative;
	height: 100%;
	font-size: 15px;
	padding-bottom: 70px;
	line-height: 1.4;
	box-shadow: 0px 0px 18px 1px rgba(34, 60, 80, 0.3);

	@include media ('>=tablet') {
		font-size: 13px;
		line-height: 1.7;
		padding-bottom: 40px;
	}

	@include media ('>=desktop') {
		font-size: $font-size-base;
	}

	.summary{
		padding: 32px 42px 0;

		@include media ('>=tablet') {
			padding: 20px;
		}

		@include media ('>=desktop') {
			padding: 21px 37px 19px;
		}
	}

	.image-holder{
		padding-bottom: 70%;
		position: relative;
	}

	.for-more-btn{
		position: absolute;
		bottom: 42px;
		left: 43px;

		@include media ('>=tablet') {
			left: 20px;
			bottom: 23px;
		}

		@include media ('>=desktop') {
			left: 36px;
		}
	}
}

.for-more-btn{
	text-transform: uppercase;
	font-weight: 600;
	color: $white;
	text-decoration: none;
	font-size: 14px;
	letter-spacing: 1px;
	display: inline-block;
	vertical-align: middle;

	@include media ('>=tablet') {
		font-size: 18px;
	}

	&:hover{
		color: $light-beige;
	}

	&:after{
		content: '>';
		position: absolute;
		right: -17px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.bg-decor{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.intro-tc{
	min-height: 370px;

	&:before{
		opacity: 0.15;
	}

	@include media ('>=xl-phone') {
		min-height: 568px;
	}

	@include media ('>=desktop') {
		min-height: 719px;
	}
}

.accordion-holder{
	padding: 42px 0 75px;

	@include media ('>=desktop') {
		padding: 86px 0 225px;
	}

	.container{
		@include media ('>=xl-phone') {
			padding: 0 50px;
		}

		@include media ('>=desktop') {
			padding: 0 15px;
			max-width: 1180px;
		}
	}

	.h1{
		letter-spacing: 2.4px;
		word-spacing: -10px;
		font-weight: 500;

		@include media ('>=desktop') {
			word-spacing: -24px;
		}
	}

	.custom-heading,
	.text-holder{
		text-align: left;
	}

	.subtitle{
		@include media ('<desktop') {
			font-size: 33px;
		}
	}

	.custom-heading{
		margin-bottom: 17px;
		line-height: 1;
		letter-spacing: 1px;

		@include media ('>=desktop') {
			margin-bottom: 33px;
		}
	}

	.text-holder{
		margin-bottom: 34px;

		@include media ('>=desktop') {
			margin-bottom: 26px;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}
	}
}

.supply-chain-holder{
	padding: 42px 0 75px;

	@include media ('>=desktop') {
		padding: 86px 0 50 0;
	}

	.container{
		@include media ('>=xl-phone') {
			padding: 0 50px;
			max-width: 75%;
		}

		@include media ('>=desktop') {
			
			padding: 0 15px;
			max-width: 62%;
		}
	}


	.custom-heading,
	.text-holder{
		text-align: left;
		font-weight: 500;

	}


	.custom-heading{
		margin-bottom: 17px;
		line-height: 1;
		letter-spacing: 1px;
		padding-top: 8rem;

		@include media ('>=desktop') {
			margin-bottom: 33px;
		}

		.title {
			font-size: 2rem;
			font-weight: bold;
			display: block;
			margin-bottom: 5rem;

			@include media ('>=desktop') {
				font-size: 2.8rem;
			}
		}
	}

	.text-holder{    
		margin-bottom: 34px;

		@include media ('>=desktop') {
			margin-bottom: 26px;
		}

		@include media ('>=widescreen') {
			font-size: 1.6rem;
		}

		@include media ('<tablet') {
			font-size: 18px !important;
			margin-bottom: 0 !important;
		}
	}
}

.for-more{
	color: $white;
	padding: 96px 0 32px;
	text-align: center;
	position: relative;

	@include media ('>=desktop') {
		padding: 132px 0 110px;
	}

	@include media ('<tablet') {
		font-size: 20px !important;
		padding: 30px 0;

		.text-holder {
			font-size: 18px !important;
			margin-bottom: 0 !important;
		}

	}

	&:after{
		@include media('<desktop') {
			content: '';
			position: absolute;
			top: -2px;
			left: 0;
			right: 0;
			background-image: url("../images/decor1.png");
			background-position: top;
			background-size: cover;
			height: 60px;
			transform: rotate(180deg);
			z-index: 1;
		}
	}

	form{
		z-index: 1;
		display: flex;
		justify-content: center;

		@include media ('<desktop') {
			flex-wrap: wrap;
		}

		@include media ('>=widescreen') {
			min-width: 800px;
			justify-content: space-between;
		}

		.form-group{
			padding: 0 10px 22px;
			width: 100%;

			@include media ('>=desktop') {
				padding: 0 10px 10px;
			}
		}
	}

	.h1{
		margin-bottom: 0;
		letter-spacing: normal;
		font-weight: 500;

		@include media ('>=desktop') {
			font-size: 45px;
			word-spacing: -9px;
		}

		@include media ('>=widescreen') {
			font-size: 65px;
		}
	}

	input[type='email']{
		width: 100%;

		@include media ('>=xl-phone') {
			max-width: 466px;
		}

		@include media ('<desktop') {
			max-width: 495px;
		}

		@include media ('>=desktop') {
			min-width: 495px;
		}
	}

	.text-holder{
		@include media ('<desktop') {
			max-width: 450px;
			margin-bottom: 18px;
		}

		@include media ('>=desktop') {
			margin-bottom: 22px;
		}
	}
}

.form-btn{
	@include animate(background-color border);

	background-color: rgba($crimson, 0.8);
	text-transform: uppercase;
	font-weight: 700;
	border: 0;
	padding: 15px 39px;
	min-width: 240px;
	border: 2px solid rgba($crimson, 0.8);
	font-family: $montserrat;

	@include media ('>=desktop') {
		padding: 16px 39px;
		min-width: 265px;
	}

	&:hover{
		background-color: rgba($crimson, 1);
		border: 2px solid rgba($crimson, 1);
	}
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
input[type='name'],
textarea{
	@include animate(border-color);

	border: 2px solid $light-beige;
	background-color: transparent;
	padding: 15px 25px;
	text-transform: uppercase;
	font-weight: 700;
	font-family: $montserrat;

	@include media ('>=desktop') {
		padding: 16px 39px;
	}

	&:focus{
		outline: none;
		border-color: $crimson;
	}
}

input::placeholder{
	text-transform: uppercase;
	color: $white !important;
	font-weight: 700;
	font-family: $montserrat;

	@include media ('<desktop') {
		text-align: center;
	}
}

.accordion-tc{
	@extend %listreset;

	padding-bottom: 50px;

	.opener{
		font-size: 29px;
		font-family: $big_caslonmedium;
		color: $light-beige;
		text-decoration: none;
		padding: 14px 0;
		display: flex;

		@include media ('<desktop') {
			line-height: 1;
		}

		@include media ('>=desktop') {
			font-size: 44px;
			padding: 11px 8px;
			letter-spacing: -0.7px;
		}

		.plus{
			position: relative;
			width: 34px;
			height: 34px;
			border: 4px solid $lagoon;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
			margin-right: 14px;
			top: 0;

			@include media ('>=desktop') {
				width: 40px;
				height: 40px;
				margin-right: 25px;
				top: 16px;
			}

			&:before,
			&:after{
				content: '';
				position: absolute;
				background-color: $lagoon;
				border-radius: 3px;
			}

			&:before{
				@include animate(transform);

				top: 50%;
				transform: translate(-50%, -50%) rotate(0);
				left: 50%;
				width: 4px;
				height: 22.5px;

				@include media ('>=desktop') {
					// width: 5px;
					height: 24px;
				}
			}

			&:after{
				@include animate(transform);

				top: 50%;
				transform: translate(-50%, -50%);
				left: 50%;
				width: 22.5px;
				height: 4px;

				@include media ('>=desktop') {
					width: 24px;
					// height: 5px;
				}
			}
		}

		.text{
			width: calc(100% - 34px);

			@include media ('>=desktop') {
				width: calc(100% - 54px);
			}
		}
	}

	li{
		&.active{
			.plus{
				&:before{
					transform: translate(-50%, -50%) rotate(90deg);
				}

				&:after{
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}
		}
	}

	.slide{
		padding: 0 0 32px 50px;

		@include media ('>=desktop') {
			padding: 19px 0 19px 95px;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}
	}
}

.intro-values, 
.intro-history{
	position: relative;
	min-height: 370px;

	@include media ('>=xl-phone') {
		min-height: 560px;
	}

	@include media ('>=desktop') {
		min-height: 587px;
	}

	&:before{
		display: none;
	}

	&:after{
		@include media('<desktop') {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			right: 0;
			background-image: url("../images/decor1.png");
			background-position: bottom;
			background-size: cover;
			height: 60px;
			z-index: 1;
		}
	}

	&.intro-fl-des{
		h1{
			letter-spacing: -1.5px;
		}
	}
}

.values-blog{
	padding: 24px 0 33px;
	background-size: auto;
	background-repeat: repeat;

	@include media ('>=xl-phone') {
		background-size: contain;
	}

	@include media ('>=desktop') {
		padding: 100px 0 25px;
	}

	.container{
		max-width: 1000px;

		@include media ('>=375px') {
			padding: 0 48px;
		}

		@include media('>=desktop') {
			padding: 0 80px;
		}

		@include media('>=widescreen') {
			padding: 0 15px;
		}
	}

	.title{
		font-size: 23px;
		color: $lightskyblue;
		display: block;
		margin-bottom: 42px;
		font-family: $big_caslonmedium;
		line-height: 1.19;
		letter-spacing: -0.6px;

		@include media ('>=xl-phone') {
			letter-spacing: 0.4px;
			font-size: 30px;
		}

		@include media ('<desktop') {
			text-align: center;
		}

		@include media ('>=desktop') {
			margin-bottom: 131px;
			font-size: 40px;
			line-height: 1;
			line-height: 1.06;
		}
	}

	.summary{
		font-size: 1.4rem;
	}
}

.blogpost{
	@extend %listreset;

	@include media ('>=xl-phone') {
		padding: 0 26px;
	}

	@include media ('>=tablet') {
		padding: 0;
	}

	li{
		margin-bottom: 15px;

		@include media ('>=xl-phone') {
			margin-bottom: 64px;
		}

		@include media ('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: 100px;
		}

		@include media ('>=desktop') {
			margin-bottom: 159px;
		}

		&:last-child{
			@include media ('<tablet') {
				margin-bottom: 10px;
			}
		}

		&:nth-child(even) {
			@include media('>=tablet') {
				flex-direction: row-reverse;
			}

			.custom-heading{
				@include media ('<tablet') {
					text-align: right;
				}
			}

			.summary{
				@include media ('<tablet') {
					text-align: right;
				}

				@include media ('>=tablet') {
					padding: 0 40px 10px 10px;
				}

				@include media ('>=desktop') {
					padding: 0 82px 10px 10px;
				}

				@include media ('>=widescreen') {
					padding: 30px 82px 10px 10px;
				}

				@include media ('>=xl') {
					padding: 50px 82px 10px 10px;
				}
			}

			.minor-decor{
				right: auto;
				left: -10px;
				bottom: -10px;

				@include media ('>=xl-phone') {
					bottom: -24px;
					left: -23px;
				}

				@include media ('>=desktop') {
					right: -61px;
					bottom: -58px;
					left: auto;
				}
			}
		}
	}

	.subtitle{
		letter-spacing: 1px;


		@include media ('>=widescreen') {
			font-size: 60px;
		}
	}

	.h1{
		letter-spacing: -1px;

		@include media ('>=xl-phone') {
			letter-spacing: 2px;
		}
	}

	.custom-heading{
		text-align: left;
		line-height: 1.1;

		@include media ('>=desktop') {
			margin-bottom: 25px;
			line-height: 1.2;
		}
	}

	.summary{
		padding: 32px 0;

		@include media ('>=tablet') {
			width: 54%;
			padding: 0 10px 10px 40px;
		}

		@include media ('>=desktop') {
			padding: 0 10px 10px 81px;
		}

		@include media ('>=widescreen') {
			padding: 30px 10px 10px 81px;
		}

		@include media ('>=xl') {
			padding: 50px 10px 10px 81px;
		}
	}

	.image-holder{
		padding-bottom: 100%;
		position: relative;
		box-shadow: 6px 7px 18px 6px rgba(0, 0, 0, 0.25);

		@include media ('>=tablet') {
			width: 46%;
			padding-bottom: 46%;
		}
	}
}

.minor-decor {
	position: absolute;
	width: 120px;
	height: 115px;
	bottom: -10px;
	right: -10px;

	@include media ('>=xl-phone') {
		right: -28px;
		bottom: -12px;
	}

	@include media ('>=xl-phone') {
		width: 170px;
		height: 150px;
		bottom: -16px;
	}

	@include media ('>=desktop') {
		width: 182px;
		height: 160px;
		bottom: -49px;
		left: -65px;
	}
}

.carousel-custom{
	#carousel {
		display: block;
	}
	#carousel img {
		cursor: pointer;
	}
}

.intro-brand-item{
	min-height: 370px;

	@include media ('>=xl-phone') {
		min-height: 563px;
	}

	@include media ('>=tablet') {
		min-height: 584px;
	}

	@include media ('>=widescreen') {
		min-height: 915px;
	}

	&:before{
		opacity: 0.1;
	}
}

.overview{
	padding: 18px 0 12px;

	@include media ('>=tablet') {
		padding: 56px 0 160px;
	}

	.subtitle{
		@include media ('<xl-phone') {
			font-size: 30px;
		}
	}

	.custom-heading{
		max-width: 400px;

		@include media ('<tablet') {
			margin: auto;
		}

		@include media ('>=tablet') {
			text-align: left;
			line-height: 1.1;
		}

		@include media ('>=desktop') {
			max-width: 500px;
		}
	}

	.container{
		display: flex;
		flex-wrap: wrap;

		@include media ('>=375px') {
			padding: 0 35px;
		}

		@include media ('>=xl-phone') {
			padding: 0 50px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}
	}

	>p{
		@include media ('>=desktop') {
			margin-bottom: 25px;
		}
	}

	.overview-paragraph{

		p{
			@include media ('>=desktop') {
				margin-bottom: 10px;
			}
		}
	}
}

.content-holder {

	.text-holder {
		padding-top: 176px;
		line-height: 1.2;

		@include media ('>=425px') {
			padding-top: 210px;
		}

		@include media ('>=xl-phone') {
			padding-top: 225px;
		}

		@include media ('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			padding-top: 0;
			line-height: 1.4;
			align-items: flex-start;
		}

		@include media ('>=widescreen') {
			line-height: 1.7;
		}

		.text{
			@include media ('>=tablet') {
				width: 50%;
				order: 1;
			}

			@include media ('>=tablet') {
				padding-right: 40px;
			}

			@include media ('>=desktop') {
				padding-right: 80px;
			}

			@include media ('>=widescreen') {
				font-size: 20px;
			}
		}

		.img-holder {
			margin: -180px 0 15px 15px;
			float: right;
			width: 150px;
			position: relative;
			&.lfb {
				@include media ('>=desktop') {
					position: relative;
					top: 40px;
				}	
			}

			@include media ('>=375px') {
				width: 54%;
			}

			@include media ('>=425px') {
				width: 49%;
				margin: -210px 0 0 25px;
			}

			@include media ('>=xl-phone') {
				margin: -224px 0 0 25px;
				width: 245px;
			}

			@include media ('>=tablet') {
				float: none;
				// margin-top: -100px;
				margin-left: 0;
				order: 2;
				padding: 0 0 0 45px;
				width: 50%;
				display: flex;
				justify-content: flex-end;
			}

			@include media ('>=desktop') {
				padding: 0 12px 0 45px;
			}

			.small-img {
				position: absolute;
				top: 32px;
				right: calc(100% - 28px);
				min-width: 175px;

				@include media ('>=xl-phone') {
					min-width: 261px;
					right: calc(100% - 25px);
				}

				@include media ('>=tablet') {
					bottom: -67px;
					top: auto;
					left: 0;
				}

				@include media ('>=widescreen') {
					left: 85px;
					min-width: auto;
				}
			}
		}
	}
}

.round-slider {
	position: relative;

	.slideset {
		position: relative;
		margin: 0 -200px 9px;

		@include media ('>=tablet') {
			width: 100%;
			margin: 0 0 5px;
		}
	}

	a{
		&:hover{
			img{
				transform: rotate(30deg);
			}
		}
	}

	.mask {
		position: absolute;
		height: 100%;
		width: 29%;
		left: 50%;
		transform: translateX(-50%);

		@include media ('>=tablet') {
			width: 27%;
		}

		&:before {
			clear: both;
			content: '';
			position: absolute;
			top: 0;
			background: $crimson;
			border-radius: 50%;
			z-index: 0;
			width: 90%;
			height: 96%;
			left: 13px;
		}

		&.ofb {
			&:before {
				background: $light-beige;
			}
		}
	}

	.slide {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transform: scale(0.5) translate(0, 0);
		transition: transform 0.65s, opacity 0.5s;
		z-index: -1;
		opacity: 0.8;

		img {
			@include animate(transform);
			width: 100%;
			height: auto;
		}

		&.center-slide{
			opacity: 1;
		}

		&:hover{
			opacity: 1;
		}
	}

	.slide.center-slide {
		transform: scale(1.1) translate(0, -3%);
		z-index: 5;
	}

	&.small {
		.slide.left-slide {
			transform: scale(0.75) translate(-104%, -15%);
			z-index: 2;
		}

		.slide.right-slide {
			transform: scale(0.75) translate(104%, -15%);
			z-index: 2;
		}

		.left-direction .slide.left-slide,
		.right-direction .slide.right-slide {
			z-index: 2;
		}

		.left-direction .slide.right-slide,
		.right-direction .slide.left-slide {
			z-index: 3;
		}
	}

	&.medium {
		.slide.left-slide {
			transform: scale(0.75) translate(-107%, -19%);
			z-index: 3;
		}

		.slide.left-slide-first {
			transform: scale(0.6) translate(-200%, -45%);
			z-index: 2;
		}

		.slide.left-slide-second {
			transform: scale(0.34) translate(-447%, -113%);
			z-index: 1;
		}

		.slide.right-slide {
			transform: scale(0.75) translate(107%, -19%);
			z-index: 3;
		}

		.slide.right-slide-first {
			transform: scale(0.6) translate(200%, -45%);
			z-index: 2;
		}

		.slide.right-slide-second {
			transform: scale(0.34) translate(447%, -113%);
			z-index: 1;
		}

		.left-direction .slide.left-slide,
		.left-direction .slide.right-slide-first,
		.right-direction .slide.left-slide-first,
		.right-direction .slide.right-slide {
			z-index: 3;
		}

		.left-direction .slide.right-slide,
		.right-direction .slide.left-slide {
			z-index: 4;
		}

		.right-direction .slide.right-slide-first {
			z-index: 2;
		}
	}


	&.large {
		.slide.left-slide {
			transform: scale(0.75) translate(-107%, -19%);
			z-index: 3;
		}

		.slide.left-slide-first {
			transform: scale(0.6) translate(-200%, -45%);
			z-index: 2;
		}

		.slide.left-slide-second {
			transform: scale(0.34) translate(-447%, -113%);
			z-index: 1;
		}

		.slide.right-slide {
			transform: scale(0.75) translate(107%, -19%);
			z-index: 3;
		}

		.slide.right-slide-first {
			transform: scale(0.6) translate(200%, -45%);
			z-index: 2;
		}

		.slide.right-slide-second {
			transform: scale(0.34) translate(447%, -113%);
			z-index: 1;
		}

		.left-direction .slide.left-slide,
		.left-direction .slide.right-slide-first,
		.right-direction .slide.left-slide-first,
		.right-direction .slide.right-slide {
			z-index: 3;
		}

		.left-direction .slide.right-slide,
		.right-direction .slide.left-slide {
			z-index: 4;
		}

		.right-direction .slide.right-slide-first {
			z-index: 2;
		}
	}

	.steps-animation .slide {
		transition: transform 0.1s;
	}

	.btn-prev,
	.btn-next {
		position: absolute;
		left: 0;
		top: 50%;
	}

	.btn-next {
		right: 0;
		left: auto;
	}
}

.otis-page{

	.decor-facts{
		@include media ('>=tablet') {
			width: 184px;
			height: 182px;
			bottom: 0px;
		}

		@include media ('>=widescreen') {
			bottom: 70px;
		}

		@include media ('>=1600px') {
			left: -1%;
		}
	}

	.quick-facts{
		@include media ('>=1600px') {
			padding: 94px 0 171px;
		}
	}
}

.decor-facts{
	display: none;

	@include media ('>=tablet') {
		position: absolute;
		display: block;
		height: 344px;
		width: 200px;
		bottom: -56px;
		left: -11%;
	}

	@include media ('>=widescreen') {
		width: 280px;
		bottom: 5px;
		left: -7%;
	}

	@include media ('>=1600px') {
		left: -3%;
	}
}

.quick-facts{
	background-color: $darkred;
	position: relative;
	padding: 70px 0 30px;

	@include media ('>=375px') {
		padding: 61px 0 96px;
	}

	@include media ('>=tablet') {
		padding: 94px 0 120px;
	}

	.container{
		max-width: 1450px;
	}

	.custom-heading{
		z-index: 1;
		position: relative;
		margin-bottom: 46px;

		@include media ('>=widescreen') {
			margin-bottom: 67px;
		}
	}

	.subtitle{
		color: $light-beige;
	}

	.h1{
		color: $white;

		@include media ('>=xl-phone') {
			font-size: 30px;
		}

		@include media ('>=desktop') {
			font-size: 64px;
		}
	}

	.bg-lines{
		&:before{
			background-size: 80%;

			@include media ('>=tablet') {
				background-size: 50%;
			}

			@include media ('>=widescreen') {
				background-size: 32%;
			}
		}
	}
}

.brand-carousel{
	padding: 0 45px;
	&.lfb {
		.slick-track {
			display: flex;
			justify-content: center;
			margin: 0 auto;
		}
	}

	@include media ('>=375px') {
		padding: 0 55px;
	}

	@include media ('<tablet') {
		max-width: 462px;
		margin: auto;
	}

	.card{
		box-shadow: none;
		text-decoration: none;
		display: block;

		&:hover{
			.img{
				@include media ('>=tablet') {
					transform: scale(1.1);
				}
			}
		}
	}

	.slick-track{
		padding: 25px 0;
	}

	.text-holder{
		text-align: center;
		background-color: transparent;
		color: $white;
		font-family: $gillsans;
		line-height: 1.5;
		padding: 31px 6px 8px;

		@include media ('>=tablet') {
			padding: 15px;
		}

		@include media ('>=desktop') {
			padding: 15px 20px;
		}

		@include media ('>=widescreen') {
			line-height: 1.9;
			padding: 15px 10px;
		}


		h4{
			font-family: $big_caslonmedium;
			font-size: 28px;
			font-weight: 500;
			letter-spacing: 1.5px;
			line-height: 1.1;
			margin-bottom: 3px;
			word-spacing: -7px;

			@include media ('>=tablet') {
				font-size: 22px;
				word-spacing: -5px;
			}

			@include media ('>=widescreen') {
				font-size: 28px;
				word-spacing: -7px;
			}
		}
	}

	.img{
		@include animate(transform);
		width: 226px;
		height: 222px;
		margin: auto;
		transform: translateY(0);

		@include media ('>=xl-phone') {
			width: 306px;
			height: 300px;
		}

		@include media ('>=tablet') {
			width: 176px;
			height: 170px;
		}

		@include media ('>=widescreen') {
			width: 226px;
			height: 222px;
			margin: 0 auto 24px;
		}
	}

	.slick-arrow{
		top: 140px;

		@include media ('<tablet') {
			width: 40px;
			height: 40px;
		}

		@include media ('>=xl-phone') {
			top: 32%;
		}

		@include media ('>=tablet') {
			top: 23%;
		}

		@include media ('>=desktop') {
			top: 114px;
		}

		&.slick-prev {
			@include media ('>=desktop') {
				left: 30px;
			}
		}

		&.slick-next {
			@include media ('>=desktop') {
				right: 27px;
			}
		}
	}

	.slick-list{
		@include media ('>=tablet') {
			padding: 24px 0;
			margin: -24px 0;
		}

	}
}

.slide-card{
	@include media ('>=tablet') {
		padding: 0 8px 15px;
	}

	@include media ('>=widescreen') {
		padding: 0 20px 40px;
	}
}

.link-block{
	min-height: 339px;
	position: relative;
	font-family: $big_caslonmedium;
	font-size: 35px;
	padding: 80px 0 53px;
	color: $white;
	text-decoration: none;

	@include media ('>=desktop') {
		font-size: 71px;
	}

	@include media ('>=widescreen') {
		min-height: 426px;
		padding: 100px 0 118px;
	}

	&:after{
		@include animate(opacity);
		content: '';
		position: absolute;
		top: -1px;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: $black;
		opacity: 0.15;
	}

	&:hover{
		&:after{
			opacity: 0.3;
		}
	}

	.container{
		z-index: 1;
	}

	span{
		position: relative;
		z-index: 1;
		word-spacing: -10px;

		@include media ('>=desktop') {
			word-spacing: -20px;
		}
	}
}

.brand-food{
	text-align: center;
	padding: 29px 0 10px;

	@include media ('>=tablet') {
		padding: 111px 0 89px;
	}
	.subtitle{
		color: $lagoon;
	}

	.h1{
		color: $light-beige;

		@include media ('<tablet') {
			font-size: 30px;
			margin-bottom: 5px;
		}
	}

	.custom-heading{
		@include media ('>=tablet') {
			margin-bottom: 28px;
		}
	}

	.text-holder{
		color: #636363;
		font-weight: 500;
		margin: 0 auto 28px;

		@include media ('>=tablet') {		
			margin: 0 auto 58px;
			max-width: 950px;
		}

		p {
			font-size: 1.25rem;
		}

	}
}

.round-carousel-nav{
	max-width: 650px;
	margin: auto;
	padding: 0 49px;

	.slick-prev{
		background-image: url("../images/arrow-blue-left@2x.png");
	}

	.slick-next{
		background-image: url("../images/arrow-blue-right@2x.png");
	}

	h4{
		font-size: 27.5px;
		font-family: $big_caslonmedium;
		line-height: 1.2;

		@include media ('>=tablet') {
			font-size: 30px;
		}
	}

	.slick-arrow{
		position: absolute;
		top: 14px;
		transform: translateY(-50%);
		font-size: 0;
		width: 35px;
		height: 35px;
		z-index: 4;
		background-color: transparent;
		border: 0;

		@include media ('>=tablet') {
			top: 26px;
		}

		@include media ('>=desktop') {
			width: 49px;
			height: 49px;
		}
	}

	.description{
		font-size: 1.4rem;
		@include media ('<tablet') {
			line-height: 1.2;
		}

		h4{
			@include media ('<tablet') {
				margin-bottom: 10px;
				line-height: 0.8;
			}
		}
	}
}

.bg-lines{
	&:before{
		content: '';
		position: absolute;
		background-image: url("../images/lines.png");
		background-repeat: repeat;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 0;
	}
}

.intro-contact{
	min-height: 300px;

	@include media ('>=xl-phone') {
		min-height: 566px;
	}

	@include media ('>=desktop') {
		min-height: 711px;
	}
}

.intro-sust{
	min-height: 300px;

	@include media ('>=xl-phone') {
		min-height: 566px;
	}

	@include media ('>=desktop') {
		min-height: 716px;
	}

	&:after{
		background-image: url("../images/decor-down.png");
	}

	.text-holder{
		font-family: $montserrat;
		font-weight: 600;

		@include media ('<xl-phone') {
			font-size: 12px;
		}

		@include media ('>=tablet') {
			max-width: 600px;
			line-height: 1.4;
			letter-spacing: 1px;
			font-family: $Myriad-Pro;
		}

		@include media ('>=desktop') {
			max-width: 780px;
			line-height: 1.7;
			letter-spacing: 1.63px;
		}

		h1{
			@include media ('>=tablet') {
				letter-spacing: 4px;
			}

			@include media ('>=desktop') {
				margin-bottom: 26px;
			}
		}

		p{
			@include media ('>=desktop') {
				margin-bottom: 24px;
			}
		}
	}
}

.future{
	color: $white;
	min-height: 370px;
	// display: flex;
	// align-items: center;
	padding: 80px 0;

	@include media ('>=tablet') {
		min-height: 400px;
	}

	@include media ('>=desktop') {
		line-height: 1.78;
		padding: 150px 0;
		min-height: 600px;
	}

	@include media ('>=widescreen') {
		min-height: 705px;
		padding: 193px 0 150px;
	}

	.container{
		width: 100%;
		align-items: flex-start;
	}

	&:before{
		background-image: url("../images/decor-down.png");
	}

	.text-holder{
		font-weight: 600;
		line-height: 1.3;

		@include media ('>=xl-phone') {
			width: 59%;
			margin-left: 36px;
		}

		@include media ('>=tablet') {
			max-width: 500px;
			width: auto;
			font-family: $Myriad-Pro;
		}

		@include media ('>=desktop') {
			font-size: 24px;
			max-width: 985px;
		}

		@include media ('>=widescreen') {
			margin-left: 46px;
		}

		h2{
			font-size: 40px;
			font-family: $freighttext_medium;
			font-weight: 500;
			line-height: 0.8;
			letter-spacing: 1.1px;
			margin-bottom: 20px;

			@include media ('>=desktop') {
				font-size: 70px;
				letter-spacing: -3px;
				word-spacing: 7px;
			}

			@include media ('>=widescreen') {
				font-size: 90px;
				margin-bottom: 39px;
			}
		}

		.main-button{
			background-color: $white;
			color: $dark-green;
			font-family: $freighttext_medium;

			@include media ('>=tablet') {
				padding: 3px 10px;
				min-width: 270px;
			}

			@include media ('>=widescreen') {
				min-width: 352px;
			}

			&:hover{
				background-color: $light-beige;
				color: $white;
			}
		}
	}
}

.focus-section{
	background-color: $lightgreen;
	padding: 26px 0 55px;
	position: relative;
	text-align: center;

	&:before{
		content: '';
		position: absolute;
		background: linear-gradient(-180deg, rgba($lightgreen,1) 25%, rgba($lightgreen,0) 100%);
		top: 0;
		right: 0;
		left: 0;
		height: 600px;
	}

	&:after{
		content: '';
		position: absolute;
		background: linear-gradient(360deg, rgba($lightgreen,1) 25%, rgba($lightgreen,0) 100%);
		bottom: 0;
		right: 0;
		left: 0;
		height: 600px;
	}

	&.bg-img{
		background-position: initial;
	}

	.container{
		position: relative;
		z-index: 1;

		@include media ('>=tablet') {
			padding: 0 40px;
		}

		@include media ('>=widescreen') {
			padding: 0 123px 0 167px;
		}
	}

	.img1{
		display: block;
	}

	.img1:hover {
		transform: scale(100.75%);
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
	}

	.img2{
		display: block;

		@include media ('>=xl-phone') {
			padding: 0 60px;
		}

		@include media ('>=tablet') {
			display: none;
		}
	}

	h3{
		font-family: $freighttext_medium;
		font-size: 25px;
		letter-spacing: -0.8px;
		font-weight: 400;
		color: $darkcyan;
		text-align: center;
		margin-bottom: 39px;


		@include media ('>=xl-phone') {
			font-size: 40px;
		}

		@include media ('<tablet') {
			line-height: 1.3;
		}

		@include media ('>=desktop') {
			font-size: 30px;
			margin-bottom: 22px;
		}

		@include media ('>=widescreen') {
			font-size: 44px;
		}
	}
}

.blue-btn{
	background-color: $lagoon;
	color: $white;
	text-decoration: none;
	font-family: $freighttext_medium;
	text-transform: none;
	min-width: 176px;
	border: none;
	font-weight: 400;
	padding: 4px 10px;

	@include media ('>=tablet') {
		font-size: 20px;
		max-width: 210px;
		padding: 8px 10px;
	}

	@include media ('>=widescreen') {
		min-width: 367px;
		font-size: 31px;
	}
}

.sust-more{
	&:after{
		display: none;
	}

	&:before{
		opacity: 0.4;
	}
}

.img-st{

	@include media ('<tablet') {
		margin-left: auto !important;
		width: 72% !important;
	}
}

.employee-info{
	display: flex;
	flex-wrap: wrap;

	@include media ('>=tablet') {
		max-width: 900px;
		margin: auto;
		justify-content: flex-end;
		align-items: flex-start;
	}

	.image-holder{
		width: 100%;
		padding-bottom: 72%;

		@include media ('>=tablet') {
			width: 40%;
			padding-bottom: 39%;
		}
	}

	.minor-decor{
		width: 145px;
		height: 127px;
		bottom: -30px;
		right: auto;
		left: -60px;

		@include media ('>=xl-phone') {
			width: 175px;
			height: 157px;
			bottom: -48px;
			left: -133px;
		}

		@include media ('>=tablet') {
			bottom: -70px;
			left: -70px;
		}

		@include media ('>=desktop') {
			left: -80px;
		}


		@include media ('>=widescreen') {
			width: 195px;
			height: 176px;
			bottom: -56px;
			left: -144px;
		}
	}

	.summary{
		@include media ('>=tablet') {
			width: 55%;
			padding: 0px 0px 0px 30px;
		}

		@include media ('>=widescreen') {
			padding: 60px 0px 0px 58px;
		}

		.h1{
			font-size: 30px;

			@include media ('>=desktop') {
				font-size: 63px;
			}
		}
	}

	.main-button{
		@include media ('>=tablet') {
			min-width: 266px;
		}
	}

	p{
		@include media ('>=tablet') {
			margin-bottom: 34px;
		}
	}

	.subtitle{
		font-weight: 500;

		@include media ('>=tablet') {
			font-size: 47px;
		}

		@include media ('>=widescreen') {
			font-size: 60px;
		}
	}

	.custom-heading{
		@include media ('>=tablet') {
			margin-bottom: 20px;
		}
	}
}

.intro-careers{
	min-height: 560px;
	padding: 110px 0;

	@include media ('>=desktop') {
		min-height: 0 !important;
		display: block;
	}

	.container{
		@include media ('>=desktop') {
			position: relative;
			display: block;
			max-width: 90%;
		}

		&:before{
			content: '';
			padding-bottom: 15%;
			display: block;
			width: 0;
			height: auto;
		}
	}

	h1{
		@include media ('>=desktop') {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&.bg-img{

		@include media ('>=tablet') {
			min-height: 380px;
			background-position: 50% 50% !important;
			background-size: cover !important;
			background-attachment: initial !important;
		}

		@include media ('>=desktop') {
			background-position: inherit;
		}

		@include media ('>=xl') {
			
		}
	}
}

.mob-head{
	display: block;
	margin-bottom: 37px;

	@include media ('>=tablet') {
		display: none;
	}
}

.mob-btn-exp{
	min-width: 260px !important;
	padding: 4px 11px !important;

	@include media ('>=tablet') {
		display: none;
	}
}

.deskt-btn {
	display: none;

	@include media ('>=tablet') {
		display: inline-block;
	}
}

.careers-more{
	&:before{
		opacity: 0.4;
	}

	&:after{
		content: '';
		position: absolute;
		top: -2px;
		left: 0;
		right: 0;
		background-image: url("../images/decor1.png");
		background-position: top;
		background-size: cover;
		height: 60px;
		transform: rotate(180deg);
		z-index: 1;
	}

	@include media ('>=tablet') {
		padding: 110px 0 77px;
	}

	.h1{
		font-size: 35px;

		@include media ('>=tablet') {
			font-size: 53px;
			font-weight: 500;
		}
	}
}

.explore{
	padding: 66px 0 27px;

	@include media ('>=xl-phone') {
		padding: 66px 38px 27px;
	}

	@include media ('>=tablet') {
		padding: 100px 0 108px;
	}

	.subtitle{
		font-weight: 500;
	}

	.h1{

		@include media ('>=tablet') {
			font-size: 30px;
		}

		@include media ('>=widescreen') {
			font-size: 50px;
			letter-spacing: 1.6px;
		}
	}

	.employee-info{
		flex-direction: column-reverse;

		@include media ('<tablet') {
			padding-bottom: 76px;
		}

		@include media ('>=tablet') {
			flex-direction: row;
		}

		.main-button{
			font-size: 15px;
		}

		.summary{
			font-size: 15px;
			line-height: 1.2;
			padding: 32px 0 4px;

			@include media ('>=tablet') {
				padding: 8px 23px 4px 37px;
			}

			@include media ('>=desktop') {
				padding: 56px 0 4px 58px;
				line-height: inherit;
				font-size: 18px;
			}

			@include media ('>=widescreen') {
				font-size: 20px;
			}

			.h1{
				@include media ('>=desktop') {
					font-size: 41px;
				}


				@include media ('>=widescreen') {
					font-size: 65px;
				}
			}

			.subtitle{
				font-size: 37px;

				@include media ('>=desktop') {
					font-size: 43px;
				}

				@include media ('>=widescreen') {
					font-size: 50px;
				}
			}
		}

		.custom-heading{
			.h1{
				@include media ('<tablet') {
					margin-bottom: 12px;
				}
			}
		}


		&.reverse-style{
			.subtitle{
				font-size: 37px;

				@include media ('>=desktop') {
					font-size: 43px;
				}

				@include media ('>=widescreen') {
					font-size: 50px;
				}
			}

			.h1{
				@include media ('>=desktop') {
					font-size: 41px;
				}


				@include media ('>=widescreen') {
					font-size: 65px;
				}
			}		
		}
	}

	&.explore-first{
		padding: 21px 0 32px;

		@include media ('>=tablet') {
			padding: 40px 0 60px;
		}

		.employee-info{
			flex-direction: row-reverse !important;

			@include media ('<tablet') {
				padding-bottom: 0;
			}

			@include media ('>=tablet') {
				max-width: initial;
				justify-content: space-between;
			}

			.custom-heading{
				display: none;

				@include media ('>=tablet') {
					display: block;
				}
			}
		}

		.container{
			@include media ('>=xl-phone') {
				padding: 0 52px;
			}

			@include media ('>=widescreen') {
				padding: 0 102px;
			}
		}

		.summary{
			padding: 0;
			line-height: 1.2;

			@include media ('>=tablet') {
				line-height: 1.27;
				padding: 0 0 20px;
				width: 50%;
			}

			@include media ('>=desktop') {
				width: 50%;
			}

			@include media ('>=widescreen') {
				padding: 0;
				line-height: 1.7;
			}

			.h1{
				@include media ('>=tablet') {
					font-size: 30px;
				}

				@include media ('>=desktop') {
					font-size: 45px;
				}

				@include media ('>=widescreen') {
					font-size: 63px;
					letter-spacing: 1.6px;
				}
			}
		}

		p{
			margin-bottom: 10px;

			@include media ('>=tablet') {
				margin-bottom: 29px;
			}
		}

		.image-holder{
			padding-bottom: 66%;
			margin-bottom: 12px;

			@include media ('>=tablet') {
				width: 50%;
				padding-bottom: 33%;
				margin-bottom: 0;
			}

			@include media ('>=desktop') {
				width: 49%;
				margin-top: 5px;
			}
		}

		.subtitle{
			@include media ('>=tablet') {
				font-size: 30px;
			}

			@include media ('>=widescreen') {
				font-size: 54px;
			}
		}

		.custom-heading{

			@include media ('>=tablet') {
				margin-bottom:24px;
				line-height: 1;
			}

			.h1{
				@include media ('<tablet') {
					font-size: 27px;
				}
			}
		}

		.text{

			@include media ('<xl-phone') {
				line-height: 1.2;
			}

			@include media ('>=tablet') {
				padding: 0 20px;
			}

			@include media ('>=desktop') {
				padding: 0 20px;
				font-size: 18px;
			}

			@include media ('>=widescreen') {
				padding: 0 39px;
				font-size: 20px;
			}
		}

		.main-button{
			padding: 4px 18px 4px;

			@include media ('>=tablet') {
				padding: 7px 11px 8px;
			}
		}
	}
}

.for-you{
	background-color: $dark-lagoon;
	padding: 75px 0 200px;
	color: $white;

	@include media ('>=tablet') {
		padding: 84px 0 200px;
	}

	&:after{
		z-index: 0;
	}

	.bg-lines{
		&:before{
			background-size: 80%;

			@include media ('>=tablet') {
				background-size: 50%;
			}

			@include media ('>=widescreen') {
				background-size: 32%;
			}
		}
	}

	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;

		@include media ('>=375px') {
			padding: 0 45px;
		}

		@include media ('>=xl-phone') {
			padding: 0 52px;
		}

		@include media ('>=desktop') {
			padding: 0 130px 0 100px;
		}
	}

	.main-button{
		color: $white;
		min-width: 260px;
		padding: 4px 11px;

		@include media ('>=desktop') {
			min-width: 267px;
			margin-left: 41px;
			padding: 7px 11px 8px;
		}
	}

	.custom-heading{
		width: 100%;
		line-height: 1;
		text-align: left;
		margin-bottom: 48px;

		@include media ('>=tablet') {
			width: 32%;
			padding-top: 95px;
			margin-bottom: 0;
		}
	}

	h3{
		font-family: $big_caslonmedium;
		font-weight: 500;
		font-size: 35px;
		margin-bottom: 23px;

		@include media ('>=tablet') {
			word-spacing: -10px;
		}

		@include media ('>=desktop') {
			font-size: 60px;
		}

		@include media ('>=widescreen') {
			margin-bottom: 58px;
			letter-spacing: -3px;
			font-size: 81px;
			word-spacing: -20px;
		}
	}
}

.opening-list{
	@extend %listreset;

	margin-bottom: -162px;
	z-index: 1;

	@include media ('>=xl-phone') {
		padding: 0 38px;
	}

	@include media ('>=tablet') {
		width: 68%;
		margin-bottom: -150px;
		padding: 0;
	}

	@include media ('>=widescreen') {
		width: 63%;
		margin-bottom: -170px;
	}

	.image{
		min-height: 122px;
		width: 100%;
		margin-bottom: 19px;

		&.bg-img{
			@include animate(background-size);
			@include media ('>=tablet') {
				background-size: 100%;
			}
		}
	}

	.opening-card{
		h4{
			font-family: $big_caslonmedium;
			color: #b7b7b7;
			font-weight: 500;
			margin-bottom: 14px;
			line-height: 1;

			@include media ('<tablet') {
				font-size: 25px;
			}

			@include media ('>=widescreen') {
				letter-spacing: 1px;
				word-spacing: -8px;
			}
		}
	}

	li{
		&:nth-child(even){
			.opening-card {
				margin-right: auto;
				margin-left: unset;
			}
		}
	}
}

.opening-card{
	color: $white;
	display: block;
	text-decoration: none;
	background-color: rgba($gray3, 0.75);
	padding: 23px;
	margin-left: auto;
	margin-bottom: 32px;

	@include media ('>=tablet') {
		width: 75%;
		margin-bottom: 22px;
	}

	@include media ('>=desktop') {
		width: 59%;
		margin-bottom: 44px;
	}

	&:hover{
		background-color: rgba($gray3, 1);

		// .bg-img{
		// 	@include media ('>=tablet') {
		// 		background-size: 120%;
		// 	}
		// }
	}

	.text{
		@include media ('<tablet') {
			line-height: 1.7;
		}

		@include media ('>=desktop') {
			letter-spacing: 0.9px;
		}
	}
}

.card-carousel{
	padding: 0 12px;

	@include media ('>=xl-phone') {
		padding: 0 8px;
	}
}

.carousel-wrapper{

	@include media ('>=xl-phone') {
		margin: 0 -240px;
		display: flex;
	}

	@include media ('>=xl') {
		margin: 0px -550px 0 -333px;
	}

	.slick-list{
		margin: 0 -8px;
	}

	.slick-track{
		margin: 0 -4px;

		@include media ('>=xl-phone') {
			margin: 0;
		}
	}

	.company-carousel,
	.company-carousel2{
		overflow: hidden;
		z-index: 1;
		margin-bottom: 0 -8px;

		@include media ('>=xl-phone') {
			margin: 0 -8px;
		}

		.slick-list{
			padding: 20px 15px;
			margin: 0 -15px;

			@include media ('>=xl-phone') {
				margin: 0 -14px;
				height: 100%;
				padding: 20px 15px;
			}
		}

		.slick-track {
			height: 100%;
		}

		.image-holder {
			height: 100%;
		}

		.slick-slide {
			.image-holder {
				box-shadow: 0 0 12px -3px rgba(#000, 39%);

				@include media ('>=xl-phone') {
					box-shadow: 0 0 12px -3px rgba(#000, 39%);
				}

				&:after {
					display: none;
				}
			}
		}
	}

	.company-carousel{
		width: 100%;

		@include media ('<xl-phone') {
			margin-bottom: 15px;
		}

		@include media ('>=xl-phone') {
			width: 38%;
		}

		@include media ('>=tablet') {
			width: 43%;
		}

		.image-holder{
			padding-bottom: 100%;

			@include media ('>=tablet') {
				padding-bottom: 103%;
			}

			@include media ('>=xl') {
				padding-bottom: 100.3%;
			}
		}

	}

	.company-carousel2{
		display: none;

		@include media ('>=xl-phone') {
			display: block;
			width: 22%;
		}

		@include media ('>=widescreen') {
			width: 20%;
		}

		@include media ('>=xl') {
			width: 22%;
		}

		.image-holder{
			padding-bottom: 175%;

			@include media ('>=375px') {
				padding-bottom: 179%;
			}

			@include media ('>=tablet') {
				padding-bottom: 110%;
			}

			@include media ('>=xl') {
				padding-bottom: 86%;
			}
		}
	}

	.text-wrapper{
		width: 100%;
		position:  relative;
		padding: 0 15px;

		@include media ('>=xl-phone') {
			width: 40%;
			padding: 13px 28px 0 46px;
		}

		@include media ('>=tablet') {
			width: 35%;
			font-size: 14px;
			position: relative;
		}

		@include media ('>=widescreen') {
			padding: 20px 30px 20px 77px;
			width: 32%;
		}

		@include media ('>=xl') {
			padding: 50px 53px 29px 101px;
			font-size: 15px;
			width: 28%;
		}

		.inner-decor{
			display: none;

			@include media ('>=tablet') {
				position: absolute;
				display: block;
				width: 112px;
				height: auto;
				left: -23px;
				bottom: 10px;
			}

			@include media ('>=desktop') {
				width: 120px;
			}

			@include media ('>=widescreen') {
				width: 150px;
				left: -39px;
			}

			@include media ('>=xl') {
				width: 192px;
				bottom: 26px;
				left: -24px;
			}
		}

		h3{
			font-family: $big_caslonmedium;
			font-size: 22px;
			margin-bottom: 2px;

			@include media ('>=tablet') {
				font-size: 17px;
				letter-spacing: -0.3px;
			}

			@include media ('>=widescreen') {
				font-size: 30px;
				margin-bottom: 8px;
			}
		}
	}

	.inner{
		@include media ('>=tablet') {
			padding-left: 20px;
		}

		@include media ('>=widescreen') {
			padding-left: 33px;
		}

		p{
			margin-bottom: 8px;
			font-size: 1.25rem;
		}

		.main-button{
			@include media ('>=tablet') {
				min-width: 250px;
				margin-top: 28px;
			}
		}
	}
}

.intro-inclusion{
	min-height: 370px;

	@include media ('>=xl-phone') {
		min-height: 561px;
	}

	@include media ('>=tablet') {
		min-height: 500px;
	}

	@include media ('>=desktop') {
		min-height: 712px;
	}
}

.diversity-paragraphs{
	position: relative;
	padding: 44px 0 78px;

	@include media ('>=tablet') {
		padding: 93px 0 0;
	}

	@include media ('>=widescreen') {
		padding: 93px 0 113px;
	}

	.bg-decor{
		left: 0;
		top: 32%;
		background-size: 32%;
		background-image: url("../images/bg-decor.png");

		@include media ('>=xl-phone') {
			left: 0;
			top: 22%;
			background-size: 28%;
		}

		@include media ('>=tablet') {
			top: 37%;
			background-size: 18%;
		}

		@include media ('>=desktop') {
			left: 0;
			top: 44%;
			background-size: 18%;
		}
	}

	.container{
		max-width: 1300px;

		@include media ('>=375px') {
			padding: 0 40px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}
	}
}

.decor-bake{
	position: absolute;
	top: 21.5%;
	right: -14px;
	width: 220px;
	height: 166px;

	@include media ('>=tablet') {
		top: 14px;
		right: -27px;
	}

	@include media ('>=desktop') {
		top: 90px;
		width: 331px;
	}
}

.decor-bake2{
	position: absolute;
	top: 69.6%;
	left: -20%;
	width: 251px;
	height: 317px;

	@include media ('>=tablet') {
		top: 40%;
		left: -16%;
		width: 220px;
	}

	@include media ('>=desktop') {
		top: 44%;
		left: 0%;
		width: 293px;
	}
}

.simple-paragraph {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding-bottom: 150px;

	@include media ('>=phone') {
		padding-bottom: 250px;
	}

	@include media ('>=xl-phone') {
		max-width: 468px;
		padding-bottom: 207px;
	}

	@include media ('>=tablet') {
		max-width: none;
		margin: 0;
		padding-bottom: 130px;
	}

	@include media ('>=desktop') {
		padding-bottom: 110px;
	}

	@include media ('>=widescreen') {
		padding-bottom: 155px;
	}

	.title-box {
		width: 100%;
		margin-bottom: 35px;

		@include media ('>=tablet') {
			width: 50%;
			margin-bottom: 0;
			padding: 0 20px 0 10px;
		}

		@include media ('>=widescreen') {
			padding: 18px 100px 0 11px;
		}

		.subtitle {
			font-weight: 700;
			font-family: $aislingregular;
			color: $lagoon;
			font-size: 35px;
			line-height: 1;
			display: block;
			margin-bottom: 3px;

			@include media ('>=tablet') {
				font-size: 40px;
				letter-spacing: -1.5px;
			}

			@include media ('>=widescreen') {
				font-size: 60px;
			}
		}

		h3 {
			font-family: $big_caslonmedium;
			color: $light-beige;
			font-size: 35px;
			line-height: 1;
			font-weight: 500;
			margin-bottom: 10px;
			letter-spacing: -2.7px;

			@include media ('>=xl-phone') {
				font-size: 43px;
			}

			@include media ('>=tablet') {
				margin-bottom: 15px;
				letter-spacing: 2px;
				font-size: 30px;
			}

			@include media ('>=desktop') {
				font-size: 43px;
			}

			@include media ('>=widescreen') {
				font-size: 65px;
				margin-bottom: 37px;
			}
		}

		.text-holder {
			font-size: 15px;
			line-height: 1.45;
			padding-left: 20px;

			@include media ('>=phone') {
				padding: 0 50px 0 40px;
			}

			@include media ('>=tablet') {
				line-height: 1.7;
				padding-right: 0;
				padding-left: 20px;
				font-size: 14px;
			}

			@include media ('>=desktop') {
				font-size: 15px;
			}

			@include media ('>=widescreen') {
				padding-left: 67px;
				font-size: 20px;
			}

			p {
				margin-bottom: 15px;

				@include media ('>=tablet') {
					margin-bottom: 10px;
				}

				@include media ('>=desktop') {
					margin-bottom: 25px;
				}
			}
		}
	}

	.img-holder {
		width: 80%;
		position: relative;
		z-index: 1;

		@include media ('<tablet') {
			margin: auto;
		}

		@include media ('>=phone') {
			width: 67%;
		}

		@include media ('>=xl-phone') {
			width: 82%;
		}

		@include media ('>=tablet') {
			align-self: flex-start;
			margin-left: auto;
			width: 40%;
		}

		@include media ('>=widescreen') {
			width: 43%;
		}

		.small-decor {
			position: absolute;
			bottom: 86px;
			right: calc(100% - 60px);
			z-index: -1;
			width: 58%;

			@include media ('>=tablet') {
				max-width: 150px;
				bottom: 95px;
				right: calc(100% - 71px);
				width: 100%;
				letter-spacing: 1.5px;
			}

			@include media ('>=widescreen') {
				max-width: 214px;
			}
		}

		.small-img {
			position: absolute;
			top: calc(100% - 88px);
			right: calc(100% - 115px);
			width: 100%;

			@include media ('>=xl-phone') {
				top: calc(100% - 171px);
				right: calc(100% - 223px);
			}

			@include media ('>=tablet') {
				max-width: 230px;
				top: calc(100% - 110px);
				right: calc(100% - 148px);
			}

			@include media ('>=widescreen') {
				max-width: 450px;
				top: calc(100% - 177px);
				right: calc(100% - 267px);
				width: 100%;
			}

			.small-description {
				position: absolute;
				font-size: 25px;
				line-height: 1;
				font-weight: 700;
				font-family: $aislingregular;
				color: $lagoon;
				min-width: 157px;
				transform: rotate(-5deg);
				top: calc(100% + -58px);
				left: calc(100% + 4px);

				@include media ('>=375px') {
					left: calc(100% + 20px);
					min-width: 170px;
					top: 120px;
				}


				@include media ('>=phone') {
					left: calc(100% + 50px);
					top: 120px;
					bottom: auto;
					font-size: 28px;
					min-width: 200px;
				}

				@include media ('>=xl-phone') {
					top: 189px;
					left: calc(100% + 13px);
					min-width: 240px;
					font-size: 34px;
				}

				@include media ('>=tablet') {
					min-width: 180px;
					font-size: 25px;
					left: calc(100% + -14px);
					top: calc(100% - 30px);
				}


				@include media ('>=desktop') {
					left: calc(100% + 27px);
					top: calc(100% - 59px);
				}

				@include media ('>=widescreen') {
					min-width: 220px;
					font-size: 30px;
					left: calc(100% + 15px);
					top: calc(100% - 150px);
				}

				@include media ('>=xl') {
					min-width: 296px;
					left: calc(100% + 30px);
					font-size: 38px;
				}
			}
		}
	}

	&.par-reverse{
		flex-direction: row-reverse;

		.img-holder{
			@include media ('>=widescreen') {
				width: 45%;
			}

			.small-img{
				top: calc(100% - 61px);
				left: calc(100% - 100px);
				width: 97%;
				@include media ('>=xl-phone') {
					left: calc(100% - 178px);
				}

				@include media ('>=tablet') {
					top: calc(100% - 85px);
					left: calc(100% - 190px);
				}

				@include media ('>=desktop') {
					top: calc(100% - 74px);
					left: calc(100% - 188px);
				}

				@include media ('>=widescreen') {
					left: calc(100% - 324px);
					max-width: 450px;
				}

				.small-description{
					min-width: 157px;
					top: calc(100% + -60px);
					right: calc(100% + 7px);
					left: auto;

					@include media ('>=375px') {
						min-width: 170px;
						right: calc(100% + 7px);
						top: 74px;
					}

					@include media ('>=phone') {
						right: calc(100% + 7px);
						transform: rotate(-5deg);
						bottom: auto;
						top: 74px;
					}

					@include media ('>=xl-phone') {
						top: 84px;
						min-width: 240px;
						right: calc(100% + 9px);
					}

					@include media ('>=tablet') {
						min-width: 180px;
						font-size: 25px;
						right: calc(100% + 0px);
						top: calc(100% - 59px);
					}

					@include media ('>=desktop') {
						right: calc(100% + 15px);
						top: calc(100% - 80px);
					}

					@include media ('>=widescreen') {
						min-width: 220px;
						font-size: 30px;
						top: calc(100% - 202px);
					}

					@include media ('>=xl') {
						right: calc(100% + 66px);
						font-size: 38px;
						min-width: 296px;
					}
				}
			}
		}

		.title-box{
			@include media ('>=tablet') {
				width: 50%;
				margin-bottom: 0;
				padding: 0 20px 0 40px;
			}

			@include media ('>=widescreen') {
				padding: 11px 65px 0 86px;
			}

			.text-holder {
				@include media ('>=widescreen') {
					padding-left: 38px;
				}
			}
		}
		&.space-top {
			@include media ('>=tablet') {
				padding-top: 100px;
			}
		}
	}
}

.incl-style{
	@include media ('>=widescreen') {
		padding: 122px 0 123px;
	}

	&:after{
		content: '';
		position: absolute;
		top: -2px;
		left: 0;
		right: 0;
		background-image: url("../images/decor1.png");
		background-position: top;
		background-size: cover;
		height: 60px;
		transform: rotate(180deg);
		z-index: 1;
	}
}

.sust-more{
	@include media ('>=widescreen') {
		padding: 128px 0 123px;
	}
}

.future-blog{
	padding-bottom: 20px;

	@include media ('>=tablet') {
		padding-bottom: 100px;
	}

	@include media ('>=widescreen') {
		padding-bottom: 239px;
	}

	.container{
		@include media ('>=375px') {
			padding: 0 50px;
		}

		@include media ('>=widescreen') {
			padding: 0 60px;
		}
	}
}

.sust-list{
	@extend %listreset;

	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 1;

	li{
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: 1;
		align-items: end;
		padding-bottom: 30px;
		flex: 0 1 100%;
		width: 100%;

		@include media ('<tablet') {
			flex-wrap: wrap;
		}

		@include media ('>=tablet') {
			padding-bottom: 50px;
			justify-content: space-between;
		}

		@include media ('>=widescreen') {
			padding-bottom: 103px;
		}

		&:nth-child(2n+1){
			flex-direction: row-reverse;

			.text{
				@include media ('<tablet') {
					text-align: right;
				}

				@include media ('>=tablet') {
					padding: 70px 20px 15px 0;
				}

				@include media ('>=widescreen') {
					padding: 99px 9px 40px 9px;
				}
			}

			.custom-heading{
				@include media ('<tablet') {
					text-align: right;
				}
			}
		}

		.image-holder{
			width: 100%;
			margin: 0 auto 30px;
			min-height: 259px;
			box-shadow: 6px 7px 18px 6px rgba(0, 0, 0, 0.25);

			@include media ('>=xl-phone') {
				width: 73%;
			}

			@include media ('>=tablet') {
				width: 48%;
				min-height: 280px;
				margin: inherit;
			}

			@include media ('>=desktop') {
				min-height: 350px;
			}

			@include media ('>=widescreen') {
				min-height: 414px;
			}
		}

		.text{
			color: #636363;
			width: 100%;
			margin: auto;


			@include media ('>=xl-phone') {
				width: 73%;
			}

			@include media ('>=tablet') {
				display: flex;
				height: 100%;
				width: 50%;
				padding: 70px 20px 15px 20px;
				flex-direction: column;
				justify-content: flex-start;
				line-height: 1.4;
			}

			@include media ('>=desktop') {
				font-size: 1.4rem;
			}

			@include media ('>=widescreen') {
				line-height: 1.6;
				padding: 84px 9px 40px 20px;
			}
		}

		.custom-heading{
			text-align: left;
			line-height: 1.1;

			.subtitle{
				font-size: 30px;
			}

			h4{
				color: $light-beige;
				font-size: 30px;
				margin-bottom: 15px;
				word-spacing: -10px;

				@include media ('>=xl-phone') {
					font-size: 36px;
				}

				@include media ('>=tablet') {
					font-size: 30px;
				}

				@include media ('>=desktop') {
					font-size: 39px;
					margin-bottom: 20px;
					word-spacing: -10px;
				}

				@include media ('>=widescreen') {
					letter-spacing: 2px;
					margin-bottom: 40px;
					word-spacing: -18px;
					font-size: 65px;
				}
			}
		}

		.custom-heading{
			text-align: left;

			@include media ('<xl-phone') {
				line-height: 1.1;
			}

			.subtitle{
				font-size: 30px;

				@include media ('>=widescreen') {
					font-size: 60px;
				}
			}
		}
	}
}

.future-paragraph{
	font-family: $Myriad-Pro;
	color: #7b7b7b;
	font-weight: 700;
	text-align: center;
	padding: 50px 0;

	@include media ('>=tablet') {
		font-size: 22px;
	}

	@include media ('>=widescreen') {
		padding: 83px 0 76px;
	}

	.container{
		max-width: 95%;
		line-height: 1.5;

		@include media ('>=widescreen') {
			max-width: 95%;
		}
	}
}

.plant-foods{
	position: relative;
	text-align: center;
	font-family: $big_caslonmedium;
	line-height: 1;
	min-height: 340px;
	display: flex;
	align-items: flex-end;
	padding: 18px 0;

	@include media ('>=tablet') {
		min-height: 380px;
	}

	@include media ('>=widescreen') {
		min-height: 590px;
	}

	.container{
		max-width: 500px;
		position: relative;
		z-index: 1;

		@include media ('>=desktop') {
			max-width: 770px;
		}
	}

	h1{
		color: $lagoon;
		margin-bottom: 30px;


		@include media ('>=widescreen') {
			margin-bottom: 90px;
		}

		@include media ('>=xl') {
			margin-bottom: 81px;
		}
	}

	span{
		color: $lightskyblue;
		font-size: 21px;

		@include media ('>=tablet') {
			font-size: 30px;
		}

		@include media ('>=widescreen') {
			font-size: 40px;
			line-height: 1.1;
		}

	}

	.right-decor{
		width: 840px;
		top: -310px;
		right: -310px;
		left: auto;

		@include media ('>=tablet') {
			width: 868px;
			top: -261px;
			right: -308px;
		}

		@include media ('>=1296px') {
			width: 1185px;
			top: -306px;
			right: -270px;
		}
	}
}

.plant-decor{
	position: absolute;
	top: 100px;
	left: -162px;
	height: auto;
	width: 250px;
	margin-bottom: 30px;

	@include media ('>=xl-phone') {
		top: 60px;
		left: -89px;
	}

	@include media ('>=tablet') {
		left: -189px;
		width: 425px;
		top: -26px;
	}

	@include media ('>=1296px') {
		width: 650px;
		top: 9px;
		left: -148px;
	}
}

.decor-plant{
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding: 30px 0;

	@include media ('>=desktop') {
		padding: 29px 0 51px;
	}
}

.corn-decor{
	height: 100%;
	width: 150px;
	position: relative;
	top: -42px;

	@include media ('>=tablet') {
		width: 200px;
	}

	@include media ('>=widescreen') {
		width: auto;
	}
}

.table-section{
	padding-bottom: 70px;

	@include media ('>=widescreen') {
		padding-bottom: 112px;
	}

	.container{
		max-width: 1010px;
	}
}

table{
	border: 2px solid $medium-beige;
	font-family: $montserrat;
	position: relative;
	width: 100%;
	box-shadow: 2px 3px 29px 4px rgba(70, 70, 70, 0.17);

	td{
		color: #7d5e47;
		font-size: 12px;
		font-weight: 500;
		padding: 5px;

		@include media ('>=tablet') {
			font-size: 14px;
			padding: 11.3px 15px;
		}
	}

	th{
		border-bottom: 2px solid $medium-beige;
		background-color: $white;
		font-size: 14px;
		font-weight: 700;
		padding: 6px 15px;

		@include media ('>=tablet') {
			font-size: 20px;
		}
	}

	td,th{
		border-right: 1px solid $medium-beige;
		text-align: center;

		&.col1{
			width: 31%;
		}

		&.col2{
			width: 38%;
		}

		&:last-child{
			border-right: 0;
		}

		p {
			margin-bottom:0;
		}
	}

	tr{
		background-color: $white;

		&:nth-child(2n){
			background-color: #f6f0eb;
		}
	}
}

.back-to-link{
	display: block;
	color: $white;
	text-decoration: none;
	font-family: $big_caslonmedium;
	font-size: 15px;
	letter-spacing: normal;
	position: absolute;
	top: -85px;
	left: 15px;
	word-spacing: -4px;

	@include media ('>=375px') {
		top: -60px;
	}

	@include media ('>=xl-phone') {
		top: -160px;
		left: 52px;
	}

	@include media ('>=tablet') {
		font-size: 20px;
		left: 15px;
	}

	@include media ('>=widescreen') {
		top: -91px;
	}

	&:hover{
		color: $lagoon;
	}
}

.future-bg-wrapper{
	text-align: inherit;
	display: flex;
	align-items: center;
}

.zoom-effect{
	&:hover{
		transform: scale(1.2);
	}
}

.bg-holder {
	opacity: 0;

	&.loaded {
		opacity: 1;
	}
}

.scale {
	display: inline-block;
	overflow: hidden;

	img{
		transition: 1s;
		display: block;

		&:hover{
			transform: scale(1.2);
		}
	}
}

.desktop .js-parallax-area .small-img img {
	position: relative;
	top: 100px;
}

.viewport .fade-effect,
.viewport .move-effect,
.viewport .move-margin-effect {
	opacity: 0;
	visibility: hidden;
}
.viewport .fade-effect {
	-webkit-transition: opacity .7s linear, visibility .7s linear;
	transition: opacity .7s linear, visibility .7s linear;
}
.viewport .move-effect {
	-webkit-transition: opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	transition: opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	transition: transform .7s linear, opacity .7s linear, visibility .7s linear;
	transition: transform .7s linear, opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	-webkit-transform: translateY(2.5rem);
	-ms-transform: translateY(2.5rem);
	transform: translateY(2.5rem);
}
.viewport .move-margin-effect {
	-webkit-transition: margin-top .7s linear, opacity .7s linear, visibility .7s linear;
	transition: margin-top .7s linear, opacity .7s linear, visibility .7s linear;
	margin-top: 2.5rem;
}
.viewport.in-viewport .move-effect,
.viewport.in-viewport .fade-effect,
.viewport.in-viewport .move-margin-effect {
	opacity: 1;
	visibility: visible;
}
.viewport.in-viewport .move-effect {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.viewport.in-viewport .move-margin-effect {
	margin-top: 0;
}
.viewport.fade-effect,
.viewport.move-effect,
.viewport.move-margin-effect {
	opacity: 0;
	visibility: hidden;
}
.viewport.fade-effect {
	-webkit-transition: opacity .7s linear, visibility .7s linear;
	transition: opacity .7s linear, visibility .7s linear;
}
.viewport.move-effect {
	-webkit-transition: opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	transition: opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	transition: transform .7s linear, opacity .7s linear, visibility .7s linear;
	transition: transform .7s linear, opacity .7s linear, visibility .7s linear, -webkit-transform .7s linear;
	-webkit-transform: translateY(2.5rem);
	-ms-transform: translateY(2.5rem);
	transform: translateY(2.5rem);
}
.viewport.move-margin-effect {
	-webkit-transition: margin-top .7s linear, opacity .7s linear, visibility .7s linear;
	transition: margin-top .7s linear, opacity .7s linear, visibility .7s linear;
	margin-top: 2.5rem;
}
.viewport.in-viewport.move-effect,
.viewport.in-viewport.fade-effect,
.viewport.in-viewport.move-margin-effect {
	opacity: 1;
	visibility: visible;
}
.viewport.in-viewport.move-effect {
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.viewport.in-viewport.move-margin-effect {
	margin-top: 0;
}

.hidden {
	display: none;
}
