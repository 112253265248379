.form-control {
	border-width: 1px !important;
	height: 39px;
	padding: 5px 14px !important;
	margin-bottom: 0 !important;
	background-color: $white !important;

	@include media ('>=tablet') {
		border-width: 2px !important;
		padding: 5px 20px !important;
		height: 50px;
	}
}

textarea {
	&.form-control {
		min-height: 210px;
		resize: none;

		@include media ('>=tablet') {
			min-height: 268px;
		}
	}
}

.contact-info {
	padding: 40px 0 118px;

	@include media ('>=desktop') {
		padding: 101px 0 68px;
	}

	.container {
		max-width: 1223px;

		@include media ('>=phone') {
			padding: 0 54px 0 45px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.text-box {
		font-family: $freighttext_medium;
		color: $beige;
		font-size: 21px;
		line-height: 1.25;
		margin-bottom: 63px;

		@include media ('>=tablet') {
			letter-spacing: -0.5px;
			width: 50%;
			padding: 0 50px 0 9px;
			margin-bottom: 0;
		}

		@include media ('>=desktop') {
			width: 46.1%;
			font-size: 30px;
			line-height: 1.25;
		}

		.phone-info {
			line-height: 1;
			letter-spacing: -0.9px;
			font-size: 30px;
			margin-bottom: 24px;

			@include media ('>=tablet') {
				padding-top: 20px;
				margin-bottom: 30px;
				line-height: 1.1;
			}

			@include media ('>=desktop') {
				margin-bottom: 96px;
				font-size: 38px;
			}

			a {
				color: $beige;
				text-decoration: none;

				&:hover{
					color: $crimson;
				}
			}

			h5 {
				margin-bottom: 9px;
				font-weight: 500;
				font-size: 38px;
        word-spacing: -.7rem;

				@include media ('>=desktop') {
					font-size: 44px;
					margin-bottom: 9px;
				}
			}

			p {
				margin-bottom: 5px;

				@include media ('>=desktop') {
					margin-bottom: 11px;
				}
			}

			.text-wrapper{
				font-size: 15px;
				letter-spacing: 0.4px;
				line-height: 1.4;
				padding-left: 15px;

				@include media ('>=375px') {
					font-size: 19px;
				}

				@include media ('>=tablet') {
					padding-left: 20px;
					font-size: 16px;
				}

				@include media ('>=desktop') {
					// font-size: 30px;
					font-size: 22px;
				}

				@include media ('>=widescreen') {
					padding-left: 33px;
					font-size: 26px;
				}
			}
		}
	}

	.contact-form {
		@include media ('>=tablet') {
			width: 50%;
		}

		@include media ('>=desktop') {
			width: 53.9%;
		}
	}
}

.contact-form {
	.btn {
		border: none;
		width: 100%;
		padding: 10px 15px;
		font-size: 17px;
		letter-spacing: -0.6px;
		font-weight: 500;
		text-align: center;
		color: $white;
		background-color: #013ca6;
		font-family: 'Montserrat';
		text-transform: uppercase;

		@include media ('>=tablet') {
			min-width: 310px;
			width: auto;
			padding: 11px 15px 12px;
			text-transform: none;
			font-size: 22px;
		}
	}

	.btn-holder {
		padding-top: 10px;
		text-align: right;

		@include media ('>=tablet') {
			padding-top: 27px;
		}
	}
}

.map-holder {
	display: none;

	@include media ('>=tablet') {
		display: block;
	}

	img {
		width: 100%;
	}
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;

	&.textarea-box {
		@include media ('>=desktop') {
			padding-top: 22px;
		}
	}

	.form-group {
		padding: 0 12px;
		margin-bottom: 15px;
		display: flex;
		flex-direction: column-reverse;
		flex: 1 0 100%;

		@include media ('>=phone') {
			margin-bottom: 31px;
		}

		@include media ('>=tablet') {
			flex: 1 0 0;
			margin-bottom: 19px;
		}

		label,
		legend {
			display: block;
			color: #013ca6;
			font-size: 17px;
			font-weight: 600;
			font-family: 'Montserrat';
			letter-spacing: -0.3px;
			margin-bottom: 9px;

			@include media ('>=tablet') {
				font-size: 22px;
				margin-bottom: 7px;
				letter-spacing: -0.6px;
			}
		}

		.form-control {
			&:required {
				& ~ label {
					&:after {
						content: "*";
						display: inline-block;
						vertical-align: top;
						color: $beige;
					}
				}
			}
		}

    .radios {

      input {
        float:left;
        position:relative;
        top:8px;
        margin-right:8px;
      }

      label {
        color:$base-text-color;
        font-size:18px;
        font-weight:500;
        margin-bottom:0;
      }
    }
	}
}

.jcf-select-form-control {
	height: 39px;
	border: 0;
	margin-bottom: 0;
	background-color: $light-beige;

	@include media ('>=tablet') {
		height: 50px;
	}

	.jcf-select-text {
		line-height: 39px;
		font-size: 17px;
		font-weight: 500;
		font-family: 'Montserrat';
		letter-spacing: -0.3px;
		color: $white;
		margin: 0 56px 0 14px;

		@include media ('>=tablet') {
			line-height: 50px;
			font-size: 22px;
			letter-spacing: -0.6px;
			margin: 0 56px 0 18px;
		}
	}

	.jcf-select-opener {
		background-color: $light-beige;
		width: 38px;

		@include media ('>=tablet') {
			width: 56px;
		}

		&:before {
			content: ">";
			font-weight: 500;
			font-size: 17px;
			font-family: 'Montserrat';
			font-weight: 500;
			color: $white;
			transform: translate(-50%, -50%) rotate(90deg);
			position: absolute;
			left: 50%;
			top: 50%;

			@include media ('>=tablet') {
				font-size: 22px;
			}
		}
	}

	.jcf-select-drop-content {
		border: 2px solid $light-beige;

		// .jcf-list {

		// }

		.jcf-option {
			font-size: 16px;
			font-weight: 500;
			font-family: 'Montserrat';
      line-height:1.2;
			padding: 13px 14px;

			@include media ('>=tablet') {
				font-size: 16px;
				padding: 18px 18px;
			}

			&.jcf-option-hideme {
				display: none;
			}
		}
	}
}


.counter-section {
	padding: 21px 0 55px;
	position: relative;
	background-repeat: repeat-x;

	@include media ('>=tablet') {
		padding: 101px 0 150px;
	}

	@include media ('>=desktop') {
		padding: 53px 0 345px;
	}

	.decor-img {
		position: absolute;
		bottom: 0;
		left: -9px;
		width: 150px;
		z-index: 2;

		@include media ('>=phone') {
			bottom: -46px;
		}

		@include media ('>=tablet') {
			width: auto;
			left: 0;
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);
		}
	}

	&.dec-up {
		&:before {
			background-image: url("../images/blue-decor-bottom.png");
			top: -55px;

			@include media ('>=phone') {
				top: -60px;
			}
		}
	}

	&.dec-down {
		&:after {
			background-image: url("../images/blue-decor-bottom.png");
			bottom: -55px;

			@include media ('>=phone') {
				bottom: -60px;
			}
		}
	}

  .section-label {
  display: flex;
 justify-content: center;
    z-index: 1;
    width: auto;
    color: white;
    font-family:  $aislingregular;
    font-size: 2rem;
    padding-bottom: 3rem;
    

    	@include media ('>=phone') {
			font-size: 3.5rem;
		}

		@include media ('>=tablet') {
			font-size: 4.5rem;
		}

		@include media ('>=desktop') {
		font-size: 5.5rem;
		}

  }

}

.count-bread {
	@extend %listreset;

	display: flex;
	flex-wrap: wrap;
	max-width: 490px;
	margin: 0 auto;
	align-items: flex-start;
	position: relative;
	z-index: 3;

	@include media ('>=tablet') {
		max-width: 1180px;
	}

	li {
		width: 100%;
		padding: 50px 35px;

		@include media ('>=phone') {
			width: 50%;
		}

		@include media ('>=tablet') {
			padding: 0 40px;
		}

		@include media ('>=desktop') {
			width: 25%;
		}

		@include media ('<phone') {
			margin-bottom: 35px;
		}

		&:nth-child(2n+2) {
			@include media ('<desktop') {
				padding-top: 80px;
				margin-bottom: -18px;
			}

			@include media ('<phone') {
				padding-top: 0;
				margin-bottom: 35px;
			}
		}

		&:nth-child(4n+2) {
			@include media ('>=desktop') {
				padding-top: 99px;
				margin-bottom: -99px;
			}
		}

		&:nth-child(4n+3) {
			@include media ('>=desktop') {
				padding-top: 222px;
				margin-bottom: -222px;
			}
		}

		&:nth-child(4n+4) {
			@include media ('>=desktop') {
				padding-top: 322px;
				margin-bottom: -300px;
			}
		}
	}

	.count-holder {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		font-family: $aislingregular;
		font-size: 53px;
		color: $white;
		line-height: 49px;
		height: 100%;
		text-align: center;
		letter-spacing: -1.5px;
		padding: 5px;
		min-height: 174px;

		@include media ('>=tablet') {
			font-size: 60px;
			min-height: 215px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(#010f2a, 0.75);
		}

		span {
			position: relative;
			z-index: 1;
			line-height: 0.65;
		}

		.counter,
		.counter-wrap {
			font-family: $big_caslonmedium;
			color: $light-beige;
			font-size: 61px;
			margin-bottom: 8px;
			line-height: 1;
			letter-spacing: normal;

			@include media ('>=tablet') {
				font-size: 75px;
			}
		}
	}
}

.inspirations-sliders {
	padding: 25px 0 28px;
	text-align: center;

	@include media ('>=desktop') {
		padding: 63px 0 28px;
	}

	.text-box {
		margin: 0 0 50px;
		font-size: 1.25rem;
		line-height: 1.2;

		@include media ('>=phone') {
			max-width: 462px;
			margin: 0 auto 67px;
		}

		@include media ('>=tablet') {
			max-width: 95%;
			margin: 0 auto 73px;
			line-height: 1.7;
		}

		@include media ('>=widescreen') {
			font-size: 1.4rem;
		}
	}

	.container {
		max-width: 1131px;

		@include media ('>=xl-phone') {
			padding: 0 51px;
		}

		@include media ('>=tablet') {
			padding: 0 15px;
		}

    .card {
      cursor:default;
    }
	}
}

.slide-box {
	text-align: center;
	margin-bottom: 33px;

	@include media ('>=tablet') {
		margin-bottom: 53px;
	}

	h2 {
		font-size: 25px;
		color: $light-beige;
		font-family: $big_caslonmedium;
		line-height: 1;
		margin-bottom: 31px;
		letter-spacing: 1.43px;
    

		@include media ('>=phone') {
			font-size: 30px;
		}

		@include media ('>=desktop') {
			font-size: 42px;
			margin-bottom: 44px;
		}

		&.decor {
			position: relative;
			font-weight: 500;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 10%;
				height: 2px;
				border-radius: 3em;
				background-color: #aa1f2e;
				left: 0;
				
				@include media ('>=375px') {
					width: 9%;	
				}

				@include media ('>=phone') {
					width: 15%;
				}

				@include media ('>=xl-phone') {
					width: 21%;
				}

				@include media ('>=tablet') {
					width: 25%;
					height: 5px;
				}

				@include media ('>=desktop') {
					width: 31%;
				}

				@include media ('>=widescreen') {
					width: 33.8%;
				}

				@include media ('>=1280px') {
					width: 369px;
				}
			}

			&:after {
				left: auto;
				right: 0;
			}
		}
	}
}

.default-slider {
	text-align: center;
	padding: 0 35px;

	@include media ('>=xl-phone') {
		padding: 0 48px;
	}

	@include media ('>=desktop') {
		padding: 0 45px;
	}

	@include media ('>=1260px') {
		padding: 0;
	}

	.slick-list {
		margin: -10px -9px;
		padding: 10px 0;
	}

	.slick-track {
		display: flex;
	}

	.slick-slide {
		padding: 0 9px;
		height: auto;
	}

	.slick-arrow {
		background-size: 100%;
		top: calc(50% - 22px);

		@include media ('>=tablet') {
			top: calc(50% - 41px);
		}

		&.slick-prev {
			background-image: url("../images/arrow-blue-left@2x.png");
			left: -10px;

			@include media ('>=xl-phone') {
				left: -18px;
			}

			@include media ('>=tablet') {
				left: -2px;
			}

			@include media ('>=desktop') {
				left: -10px;
			}

			@include media ('>=1260px') {
				left: -74px;
			}
		}

		&.slick-next {
			background-image: url("../images/arrow-blue-right@2x.png");
			right: -10px;

			@include media ('>=xl-phone') {
				right: -18px;
			}

			@include media ('>=tablet') {
				right: -2px;
			}

			@include media ('>=desktop') {
				right: -10px;
			}

			@include media ('>=1260px') {
				right: -74px;
			}
		}
	}

	.card {
		@include animate(transform);

		text-decoration: none;
		display: block;
		box-shadow: 0px 0px 10px 1px rgba(34, 60, 80, 0.5);

		
		.img-wrapper {
			@include animate(background-size);

			padding-bottom: 64.8%;

			&.bg-img{
				@include animate(background-size);
				@include media ('>=tablet') {
					background-size: 102%;
				}
			}

		}

		.text-holder {
			@include animate(color);

			padding: 21px 15px 9px;
			height: 100%;

			h3 {
				text-align: center;
				font-family: $big_caslonmedium;
				line-height: 1.15;
				letter-spacing: -0.4px;
				font-weight: 500;
				font-size: 18px;

				@include media ('>=xl-phone') {
					font-size: 26px;
				}

				@include media ('>=desktop') {
					font-size: 25px;
				}
			}
		}

		&:hover{
			.text-holder{
				color: $white;
			}

			.bg-img{
				@include media ('>=tablet') {
					background-size: 120%;
				}
			}
		}
	}
}

.our-food {
	padding: 107px 0 77px;
	text-align: center;
	position: relative;
	overflow: hidden;

	@include media ('>=tablet') {
		padding: 156px 0 25px;
	}

	.container {
		position: relative;
		z-index: 2;
	}

	.decor-img {
		position: absolute;
		z-index: 1;
		right: -200px;
		bottom: -50px;

		@include media ('>=phone') {
			right: -316px;
			bottom: -203px;
		}

		@include media ('>=tablet') {
			right: -276px;
			bottom: -119px;
		}
	}

	.title-box {
		max-width: 95%;
		margin: 0 auto;
		font-size: 15px;
		line-height: 1.25;
    display: flex;
    flex-direction: column;
    align-items: center;

		@include media ('>=phone') {
			margin: 0 auto;
		}

		@include media ('>=tablet') {
			line-height: 1.55;
			margin: 0 auto;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}

		h1 {
			color: $light-beige;
			margin-bottom: 0;
			line-height: 1;
			font-weight: 500;
     		 max-width: 545px;
		
		}

		.subtitle {
			display: block;
			font-family: $aislingregular;
			color: $lagoon;
			margin-bottom: 21px;
			font-weight: 700;
			line-height: 1;
			font-size: 28px;

			@include media ('>=phone') {
				font-size: 37px;
			}

			@include media ('>=tablet') {
				font-size: 40px;
				margin-bottom: 39px;
			}

			@include media ('>=widescreen') {
				font-size: 60px;
			}
		}
	}
}

.food-list {
	@extend %listreset;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;

	@include media ('>=phone') {
		max-width: 390px;
	}

	@include media ('>=tablet') {
		max-width: 1000px;
	}

	li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		padding: 25px 15px;
		margin-bottom: 20px;

		@include media ('>=phone') {
		}

		@include media ('>=tablet') {
			width: 50%;
			padding: 13px 15px 23px;
		}

		@include media ('>=desktop') {
			width: 33.33%;
		}

		&:hover {
			.description-holder {
				opacity: 1;
				visibility: visible;
			}

			h3 {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.img-holder {
		position: relative;
		width: 100%;
		text-align: center;

		h3 {
			@include animate(opacity visibility);

			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			background-color: rgba($white, 0.6);
			font-size: 25px;
			padding: 10px 5px;
			text-align: center;
			border: 1px solid $light-beige;
			font-family: $big_caslonmedium;
			font-weight: 500;
			color: $lagoon;

			@include media ('>=phone') {
				font-size: 36px;
			}

			@include media ('>=tablet') {
				border: 4px solid $light-beige;
				font-size: 30px;
				padding: 0;
			}
		}
	}

	.description-holder {
		@include animate(opacity visibility);

		position: absolute;
		// top: 0;
		left: 15px;
		right: 15px;
		// bottom: 0;
		// overflow: auto;
		background-color: rgba($white, 0.8);
		border: 4px solid $light-beige;
		font-size: 15px;
		padding: 18px 15px 20px 17px;
		color: $dark2;
		text-align: left;
		opacity: 0;
		z-index: 1;
		visibility: hidden;

		h2 {
			font-family: $big_caslonmedium;
			color: $lagoon;
			line-height: 1;
			margin-bottom: 11px;
			font-weight: 500;

			@include media ('>=tablet') {
				font-size: 40px;
			}
		}

		p {
			margin-bottom: 8px;
		}

		a {
			font-weight: 600;
			display: inline-block;
			vertical-align: middle;
			color: $dark2;
			text-decoration: none;

			&:hover {
				color: $lagoon;
			}
		}
	}
}

.our-food-detail {
	padding: 15px 0 35px;
	position: relative;


	&:before{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 270px;
		background-image: linear-gradient(-180deg, #ffffff 0%, rgba(255, 255, 255, 0) 80%);
	}

	@include media ('>=tablet') {
		padding: 35px 0 46px;
	}

	.text-box {
		text-align: center;
		max-width: 95%;
		margin: 0 auto 40px;
		font-size: 1.25rem;
		line-height: 1.25;
		position: relative;
		z-index: 1;

		@include media ('>=tablet') {
			line-height: 1.55;
		}

		@include media ('>=widescreen') {
			font-size: 1.4rem;
      max-width: 100%;
		}

		.subtitle {
			font-family: $aislingregular;
			color: $lagoon;
			display: block;
			font-size: 35px;
			font-weight: 700;
			margin-bottom: 10px;
			line-height: 1;

			@include media ('>=tablet') {
				margin-bottom: 5px;
				letter-spacing: 1px
			}

			@include media ('>=desktop') {
				font-size: 60px;
			}
		}
		h1 {
			word-spacing: -10px;
		}
	}
}

.our-food-detail-list {
	@extend %listreset;
	@include media ('>=phone') {
		padding: 0 36px;
	}

	@include media ('>=tablet') {
		padding: 0;
		max-width: 1091px;
		margin: 0 auto;
	}

	li {
		margin-bottom: 47px;

		@include media ('>=tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 55px;
			align-items: center;
		}

		@include media ('>=desktop') {
			margin-bottom: 144px;
		}

		.text-inner{
			@include media ('>=widescreen') {
				font-size: 1.4rem;
			}
		}

		&:nth-child(odd) {

			@include media ('>=tablet') {
				flex-direction: row-reverse;
			}

			.text-holder {
				text-align: left;
				
				@include media ('>=tablet') {
					
					padding-left: 0;
					padding-right: 15px;
				}

				@include media ('>=desktop') {
					padding-top: 20px;
				}

				@include media ('>=widescreen') {
					padding-top: 50px;
				}

				h2 {
					@include media ('>=desktop') {
						margin-bottom: 21px;
						font-size: 50px;
						word-spacing: -20px;
					}

					@include media ('>=widescreen') {
						font-size: 65px;
					}
				}

				.text-inner {
					@include media ('>=tablet') {
						margin-right: auto;
						margin-left: 0;
					}

					@include media ('>=widescreen') {
						font-size: 1.4rem;
					}
				}
			}
		}
	}

	.img-holder {
		margin-bottom: 13px;

		@include media ('>=tablet') {
			margin-bottom: 0;
			width: 50%;
		}

		@include media ('>=desktop') {
			width: 56.9%;
		}

		img {
			width: 100%;
		}
	}

	.text-holder {
		font-size: 15px;
		line-height: 1.25;
		text-align: right;

		@include media ('>=tablet') {
			width: 50%;
			padding-left: 15px;
			line-height: 1.7;
		}

		@include media ('>=desktop') {
			padding-top: 36px;
			width: 43.1%;
			text-align: left;
		}

		.text-inner {
			@include media ('>=tablet') {
				max-width: 371px;
				margin-left: auto;
			}

			@include media ('>=desktop') {
				max-width: 430px;
			}
		}

		h2 {
			color: $light-beige;
			font-family: $big_caslonmedium;
			font-weight: 500;
			margin-bottom: 4px;
			line-height: 1;
			font-size: 32px;
			word-spacing: -10px;

			@include media ('>=desktop') {
				font-size: 50px;
				margin-bottom: 14px;
			}

			@include media ('>=widescreen') {
				font-size: 65px;
			}
		}
	}
}

.pagination-bread {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 1110px;
	margin: 0 auto;
	position: relative;
	
	@include media ('>=530px') {
		padding: 0 31px 0 35px;
	}

	@include media ('>=desktop') {
		padding: 0;
	}

	.pagination-link {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		font-family: $big_caslonmedium;
		color: $light-beige;
		font-weight: 500;
		justify-content: space-between;
		font-size: 15px;

		@include media ('>=phone') {
			font-size: 22px;
		}

		@include media ('>=tablet') {
			font-size: 25px;
		}

		&.prev-page {
			img {
				align-self: flex-start;

				@include media ('>=tablet') {
					align-self: center;
				}
			}
		}

		&.next-page {
			text-align: right;
			align-items: flex-end;
		}

		img {
			max-width: 50px;
			
			@include media ('>=phone') {
				max-width: 86px;
				margin-bottom: 8px;
			}

			@include media ('>=desktop') {
				max-width: 176px;
				margin-bottom: 0;
			}
		}

		&:hover {
			color: $lagoon;
		}
	}

	.description {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		align-self: center;
		font-family: $big_caslonmedium;
		color: $light-beige;
		font-weight: 500;
		line-height: 1;
		font-size: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		word-spacing: -15px;

		@include media ('>=phone') {
			font-size: 32px;
		}

		@include media ('>=desktop') {
			font-size: 60px;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			right: calc(100% + 10px);
			height: 2px;
			width: 20px;
			background-color: #91192b;

			@include media ('>=phone') {
				width: 45px;
			}

			@include media ('>=tablet') {
				width: 80px;
			}

			@include media ('>=desktop') {
				width: 108px;
			}
		}

		&:after {
			right: auto;
			left: calc(100% + 10px);
		}
	}
}

.who-we-are {
	padding-top: 44px;
	padding-bottom: 240px;

	@include media ('>=375px') {
		padding-bottom: 280px;
	}

	@include media ('>=phone') {
		padding-bottom: 286px;
	}

	@include media ('>=tablet') {
		padding-top: 100px;
		padding-bottom: 213px;
	}

	@include media ('>=desktop') {
		padding-top: 150px;
		padding-bottom: 261px;
	}

	.container {
		max-width: 1130px;
	}
}

.simple-text-box {
	display: flex;
	flex-wrap: wrap;
	max-width: 448px;
	margin: 0 auto;

	@include media ('>=tablet') {
		max-width: none;
		margin: 0;
	}


	.title-box {
		width: 100%;
		margin-bottom: 15px;

		@include media ('>=tablet') {
			width: 50%;
			padding-right: 40px;
			margin-bottom: 0;
		}

		@include media ('>=desktop') {
			padding-right: 20px;
		}

		.subtitle {
			font-weight: 500;
			font-family: $aislingregular;
			color: $lagoon;
			font-size: 35px;
			line-height: 1;
			display: block;
			margin-bottom: 3px;
			
			@include media ('>=tablet') {
				font-size: 40px;
				letter-spacing: -1.5px;
			}

			@include media ('>=widescreen') {
				font-size: 60px;
			}
		}

		h3 {
			font-family: $big_caslonmedium;
			color: $light-beige;
			font-size: 35px;
			line-height: 1;
			font-weight: 500;
			margin-bottom: 18px;
			letter-spacing: -2.7px;

			@include media ('>=tablet') {
				font-size: 45px;
				margin-bottom: 15px;
				letter-spacing: -2.5px;
			}

			@include media ('>=widescreen') {
				font-size: 65px;
				margin-bottom: 26px;
			}
		}

		.text-holder {
			font-size: 1.25rem;
			line-height: 1.45;
			color: #333;
			padding-left: 31px;

			@include media ('>=phone') {
				padding-right: 50px;
        	font-size: 1.25rem;
			}

			@include media ('>=tablet') {
				line-height: 1.7;
				padding-right: 0;
				padding-left: 20px;
			}

			@include media ('>=widescreen') {
				padding-left: 40px;
				// font-size: 20px;
			}

			p {
				margin-bottom: 15px;
			}
		}
	}

	.img-holder {
		width: 67%;
		position: relative;
		margin-left: auto;

		@include media ('>=phone') {
			width: 67%;
		}

		@include media ('>=560px') {
			width: 71%;
		}

		@include media ('>=tablet') {
			align-self: flex-start;
			margin-left: auto;
			width: 40%;
		}

		@include media ('>=widescreen') {
			width: 33%;
		}

		.small-decor {
			position: absolute;
			bottom: 86px;
			right: calc(100% - 60px);
			z-index: -1;
			width: 58%;

			@include media ('>=tablet') {
				max-width: 150px;
				bottom: 95px;
				right: calc(100% - 71px);
				width: 100%;
			}

			@include media ('>=widescreen') {
				max-width: 214px;
			}
		}

		.small-img {
			position: absolute;
			top: calc(100% - 93px);
			right: calc(100% - 91px);
			width: 84%;

			@include media ('>=tablet') {
				max-width: 200px;
			}

			@include media ('>=widescreen') {
				max-width: 303px;
				top: calc(100% - 107px);
				right: calc(100% - 104px);
			}

			img{
				box-shadow: 3px 7px 31px 4px rgba(54, 54, 54, 0.69);
			}

			.small-description {
				position: absolute;
				left: 15px;
				font-size: 28px;
				line-height: 1;
				font-weight: 700;
				font-family: $aislingregular;
				color: $lagoon;
				min-width: 240px;
				top: calc(100% + 5px);
				

				@include media ('>=phone') {
					left: calc(100% + 29px);
					transform: rotate(-5deg);
					bottom: 16px;
					top: auto;
				}

				@include media ('>=tablet') {
					min-width: 180px;
					font-size: 25px;
					left: calc(100% + 15px);
					top: calc(100% - 50px);
				}

				@include media ('>=widescreen') {
					min-width: 240px;
					top: auto;
					left: calc(100% + 35px);
					bottom: 15px;
					font-size: 32px;
				}
			}

			&.shadow-img{
				.small-description{
					transform: rotate(0deg);
					top: auto;
					bottom: -106px;

					@include media ('>=phone') {
						bottom: -6px;
					}

					@include media ('>=tablet') {
						bottom: -45px;
						top: auto;
					}

					@include media ('>=widescreen') {
						bottom: -28px;
					}
				}
			}
		}
	}
}

.shadow-img{
	transform: rotate(-6deg);
}

.our-values {
	text-align: center;
	padding: 79px 0 0px;

	@include media ('>=tablet') {
		padding: 85px 0 0px;
	}

	@include media ('>=desktop') {
		padding: 104px 0 0px;
	}

	.title-holder {
		margin-bottom: 6px;
		position: relative;
		z-index: 3;

		@include media ('>=tablet') {
			margin-bottom: 0;
		}

		.subtitle {
			font-family: $aislingregular;
			color: $lagoon;
			line-height: 1;
			margin-bottom: 6px;
			display: block;
			font-weight: 500;
			font-size: 35px;

			@include media ('>=desktop') {
				font-size: 60px;
				margin-bottom: 6px;
			}
		}

		h3 {
			font-family: $big_caslonmedium;
			color: $light-beige;
			margin-bottom: 0;
			font-weight: 500;
			line-height: 1;
			font-size: 35px;

			@include media ('>=desktop') {
				font-size: 65px;
			}
		}
	}
}

.our-values-slider-holder {
	position: relative;
	@include media ('<tablet') {
		margin-bottom: 40px;
	}

	@include media ('>=tablet') {
		padding: 35px 0 50px;
	}

	@include media ('>=desktop') {
		padding: 130px 0 235px;
	}

	.decor-bg {
		display: none;

		@include media ('>=tablet') {
			display: block;
			position: absolute;
			top: -41px;
			right: -53px;
			bottom: 0;
			left: 0;
			background-position: 50% 0;
			background-repeat: no-repeat;
			z-index: 1;
			background-size: 100%;
		}

		@include media ('>=desktop') {
			background-size: auto;
			background-position: 50% 0;
		}
	}

	.container {
		position: relative;
		z-index: 2
	}

	.main-button {
		@include media ('>=tablet') {
      color: $white;
      background-color: $light-beige;
			min-width: 239px;
			border-width: 4px;

      &:hover, &:focus {
        color:$lagoon;
        background-color:#fff;
      }
		}
	}
}

.our-values-slider {
	max-width: 923px;
	margin: 0 auto 33px;
	&.unslick {
		display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    > div {
    	flex: 0 1 33.3333%;
    	padding: 15px;
    	width: 33.3333%;
    	@include media ('<tablet') {
				flex: 0 1 50%;
    		padding: 10px;
    		width: 50%;
			}
    }

	}

	@include media ('>=tablet') {
		margin: 0 auto 49px;
	}

	.slick-track {
		display: flex;
	}

	.slick-list {
		margin: 0 -18px;
	}

	.slick-slide {
		padding: 25px 18px 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;

		@include media ('>=tablet') {
			flex-direction: row;
			height: auto !important;
			align-items: flex-start;
		}
	}

	.slick-arrow {
		transform: translate(0, 0);
		top: 90px;
		left: 0;
		background-image: url('../images/arrow-next.png');
		background-size: 100%;
		background-repeat: no-repeat;

		@include media ('>=phone') {
			width: 56px;
			top: 107px;
			height: 56px;
			left: 30px;
		}

		@include media ('>=tablet') {
			width: 39px;
			height: 39px;
			top: 77px;
			left: -8px;
		}

		&.slick-next {
			left: auto;
			right: 0;

			@include media ('>=phone') {
				right: 30px;
			}

			@include media ('>=tablet') {
				right: -8px;
			}
		}

		&.slick-prev {
			background-image: url('../images/arrow-prev.png');
		}
	}

	.img-holder {
		@include animate(transform);

		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto 32px;
		width: 150px;
		height: 150px;

		@include media ('>=phone') {
			margin: 0 auto;
		}

		@include media ('>=tablet') {
			margin: 0 auto 32px;
			width: 144px;
			height: 144px;
			transform: translateY(0);
		}

		img {
			@include media ('<tablet') {
				max-width: 85px;
				width: 100%;
			}
		}
	}

	.text-holder {
		@include animate(background-color);

		width: 100%;
		background-color: rgba(#26252c, 0.7);
		color: $white;
		font-size: 15px;
		line-height: 1.4;
		height: 100%;
		padding: 20px 15px 6px;
		margin: 0 auto;
		max-width: 391px;

		@include media ('>=tablet') {
			line-height: 1.65;
			max-width: none;
			padding: 9px 20px;
		}

		@include media ('>=desktop') {
			padding: 16px 20px;
		}

		p {
			margin-bottom: 0;
		}

		h5 {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 0px;
			line-height: 1;
			letter-spacing: 2px;

			@include media ('>=phone') {
				margin-bottom: 7px;
				font-size: 20px;
			}

			@include media ('>=tablet') {
				font-size: 25px;
				margin-bottom: 5px;
			}

			@include media ('>=desktop') {
				margin-bottom: 10px;
			}
		}
	}

	a{
		text-decoration: none;
		height: 100%;
		display: block;

		&:hover{
			.text-holder{
				background-color: rgba(#26252c, 0.85);
			}

			.img-holder{
				@include media ('>=tablet') {
					transform: translateY(-15px);
				}
			}
		}
	}
}

.our-team {
	padding: 62px 0 35px;

	@include media ('>=tablet') {
		padding: 31px 0 50px;
	}

	.title-holder {
		text-align: center;
		margin-bottom: 20px;

		@include media ('>=tablet') {
			margin-bottom: 20px;
		}

		.subtitle {
			font-family: $aislingregular;
			color: $lagoon;
			line-height: 1;
			margin-bottom: 4px;
			display: block;
			font-size: 35px;
			font-weight: 500;

			@include media ('>=desktop') {
				font-size: 60px;
			}
		}

		h3 {
			font-family: $big_caslonmedium;
			color: $light-beige;
			margin-bottom: 0;
			font-weight: 500;
			line-height: 1;
			font-size: 35px;

			@include media ('>=desktop') {
				font-size: 65px;
			}
		}
	}
}

.our-team-slider {
	@include media ('>=phone') {
		margin: 0 auto;
		max-width: 420px;
	}

	@include media ('>=tablet') {
		max-width: 893px;
		margin: 0 auto 39px;
	}

	.slick-list {
		margin: 0 -35px;
		padding: 30px 0 25px;

		@include media ('>=tablet') {
			padding: 15px 0 25px;
		}
	}

	.slick-arrow {
		top: 0;
		left: 0;

		@include media ('>=phone') {
			width: 56px;
			height: 56px;
			top: 129px;
			left: -26px;
		}

		@include media ('>=tablet') {
			display: none !important;
		}

		&.slick-next {
			left: auto;
			right: 0;

			@include media ('>=phone') {
				right: -26px;
			}
		}
	}

	.slick-slide {
		padding: 0 35px;

		@include media ('>=tablet') {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.img-holder {
		max-width: 328px;
		margin: 0 auto 30px;
		box-shadow: 0 6px 24px 5px rgba(23, 22, 26, 0.6);

		@include media ('>=tablet') {
			box-shadow: 2px 6px 24px 5px rgba(23, 22, 26, 0.6);
			align-self: flex-start;
			max-width: none;
			margin: 18px 0 0;
			width: 35.3%;
		}

		img {
			width: 100%;
		}
	}

	.text-holder {
		font-size: 15px;
		text-align: center;
		color: #333;

		@include media ('>=tablet') {
			width: 64.7%;
			text-align: left;
			padding-left: 35px;
			line-height: 1.7;
		}

		@include media ('>=desktop') {
			padding-left: 66px;
			padding-right: 45px;
		}

		h4 {
			color: #aa1f2e;
			font-family: $aislingregular;
			line-height: 1;
			margin-bottom: 5px;
			font-weight: 600;
			font-size: 28px;

			@include media ('>=phone') {
				font-size: 35px;
			}

			@include media ('>=tablet') {
				margin-bottom: 10px;
				font-size: 45px;
				color: $light-beige;
			}
		}

		.text-box {
			@include media ('>=tablet') {
				padding-left: 35px;
			}

			p {
				@include media ('<tablet') {
					margin-bottom: 7px;
				}
			}
		}
	}
}

.our-name {
	margin-bottom: 35px;

	@include media ('>=desktop') {
		margin-bottom: 41px;
	}

	.title-holder {
		text-align: center;
		margin-bottom: -17px;
		position: relative;
		z-index: 2;

		@include media ('>=tablet') {
			margin-bottom: -29px;
		}

		.subtitle {
			font-family: $aislingregular;
			color: $light-beige;
			line-height: 1;
			margin-bottom: 4px;
			display: block;
			// font-weight: 700;
			font-size: 35px;

			@include media ('>=desktop') {
				font-size: 60px;
			}
		}

		h3 {
			font-family: $big_caslonmedium;
			color: #aa1f2e;
			margin-bottom: 0;
			font-weight: 500;
			line-height: 1;
			font-size: 35px;

			@include media ('>=desktop') {
				font-size: 65px;
			}
		}
	}
}

.our-name-description {
	@include media ('>=phone') {
		max-width: 466px;
		margin: 0 auto;
	}

	@include media ('>=tablet') {
		max-width: 916px;
	}

	.img-holder {
		position: relative;
		margin-bottom: 17px;

		@include media ('>=tablet') {
			margin-bottom: 24px;
		}

		&:before {
			@include media ('>=tablet') {
				content: "";
				position: absolute;
				top: -7px;
				right: -1px;
				bottom: -7px;
				left: -1px;
				background-image: url('../images/our-name-decor-img.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}
		}
	}

	.text-holder {
		color: #333;

		@include media ('>=phone') {
			padding: 0 35px;
		}

		@include media ('>=tablet') {
			padding: 0;
			// column-count: 2;
		}

		@include media ('>=widescreen') {
			font-size: 20px;
		}

		p {
			@include media ('<tablet') {
				margin-bottom: 8px;
			}
		}

		img {
			float: right;
			margin-top: -9px;
			margin-right: -15px;

			@include media ('<tablet') {
				max-width: 126px;
				margin-top: -23px;
				margin-right: -22px;
			}
		}
	}
}

.intro-food {
	color: $white;
	min-height: 370px;
	position: relative;

	@include media ('>=xl-phone') {
		min-height: 561px;
	}

	@include media ('>=tablet') {
		min-height: 564px;
	}

	.container{
		max-width: 1255px;
		width: 100%;
	}

	.text-holder {
		padding-top: 10px;

		@include media ('>=widescreen') {
			padding-top: 45px;
		}
	}
}

.for-you {
	&.our-company {
		background-color: transparent;
		color: $dark2;
		padding-top: 37px;
		padding-bottom: 50px;
		background-position-x: -29px;
		background-position-y: 100%;
		background-repeat: no-repeat;
		background-size: 130% auto;

		@include media ('>=phone') {
			padding-bottom: 200px;
		}

		@include media ('>=tablet') {
			padding-top: 84px;
			background-position-x: -161px;
			background-size: auto;
			background-position-y: calc(100% + 14px);
		}

		@include media ('>=widescreen') {
			padding-bottom: 149px;
		}

		.container {
			max-width: 1123px;

			@include media ('>=tablet') {
				padding: 0 15px;
			}
		}

		h3 {
			color: $light-beige;

			@include media ('>=phone') {
				margin-bottom: 38px;
			}

			@include media ('>=desktop') {
				font-size: 50px;
				margin-bottom: 26px;
				letter-spacing: -0.2px;
			}

			@include media ('>=widescreen') {
				min-width: 605px;
				font-size: 65px;
			}
		}

		.subtitle {
			color: $lagoon;
		}

		.main-button {
			color: $lagoon;

			@include media ('>=563px') {
				margin-left: 40px;
			}

			@include media ('>=tablet') {
				border-width: 4px;
				margin-left: 25px;
			}

			@include media ('>=desktop') {
				margin-left: 27px;
				min-width: 240px;
			}

			&:hover{
				color: $white;
			}
		}

		.custom-heading {
			@include media ('>=563px') {
				margin-left: -40px;
			}

			@include media ('>=tablet') {
				padding-top: 0;
				margin-left: 0;
			}

			@include media ('>=desktop') {
				padding-top: 78px;
			}

			@include media ('>=widescreen') {
				width: 43.7%;
			}

			.text-box {
				font-size: 15px;
				line-height: 1.6;

				@include media ('>=563px') {
					padding-left: 40px;
				}

				@include media ('>=tablet') {
					padding-left: 25px;
					line-height: 1.5;
					margin-bottom: 25px;
				}

				@include media ('>=desktop') {
					margin-bottom: 49px;
				}

				@include media ('>=widescreen') {
					font-size: 20px;
				}
			}
		}

		.opening-list {
			margin-bottom: 0;

			@include media ('>=widescreen') {
				width: 56.3%;
			}

			li {
				margin-bottom: 48px;

				@include media ('>=desktop') {
					margin-bottom: 62px;
				}
			}

			.opening-card {
				background-color: rgba(#bf9372, 0.3);
				color: $dark2;
				position: relative;
				padding: 22px 16px 26px 29px;
				margin-bottom: 0;

				@include media ('>=desktop') {
					width: 64%;
				}

				h4 {
					font-weight: 700;
					color: $light-beige;
					font-family: $gillsans;
					letter-spacing: -1px;
					word-spacing: inherit;
				}

				.decor-subtitle {
					color: #aa1f2e;
					font-family: $aislingregular;
					font-weight: 700;
					display: none;
					
					@include media ('>=tablet') {
						display: block;
						font-size: 32px;
						max-width: 245px;
						position: absolute;
						top: -47px;
						left: calc(100% + 24px);
						width: 100%;
						transform: rotate(-4deg);
						line-height: 0.95;
					}
				}
			}
		}
	}
}

.our-company-intro {
	background-position: center 100%;

	&:before {
		display: none;
	}
}

.our-company-body {
	background-repeat: no-repeat;
	background-size: auto 100%;

	@include media ('>=tablet') {
		background-size: 100% 100%;
	}
}

.pagination-list {
	@extend %listreset;

	display: none;

	@include media ('>=tablet') {
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		max-width: 1026px;
		margin: 0 auto 25px;
	}

	@include media ('>=desktop') {
		margin: 0 auto 60px;
	}

	@include media ('>=widescreen') {
		margin: 0 auto 138px;
	}

	li {
		width: 12.5%;
	}

	a {
		text-decoration: none;
		color: $dark2;
		display: block;

		&.active,
		&:hover {
			.decor-circle {
				border-color: #aa1f2e;
				border-width: 4px;

				&:before {
					transform: translate(-50%, -50%) scale(1);
				}
			}
		}
	}

	.decor-circle {
		@include animate(border-color border-width);

		display: block;
		margin: 0 auto 31px;
		width: 29px;
		height: 29px;
		border-radius: 50%;
		border: 3px solid #9fbac7;
		background-color: $white;
		position: relative;

		&:before {
			@include animate(transform);

			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 62px;
			height: 62px;
			border-radius: 50%;
			border: 4px solid #aa1f2e;
			z-index: -1;
			background-color: $white;
			transform: translate(-50%, -50%) scale(0);
		}

		&:after {
			content: "";
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 4px;
			background-color: #9fbac7;
			width: 200px;
			position: absolute;
			z-index: -2;
		}
	}

	.text-box {
		line-height: 1;
		font-size: 13px;
		font-family: $gillsans;

		@include media ('>=desktop') {
			font-size: 15px;
		}

		h6 {
			font-weight: 600;
			margin-bottom: 7px;
			font-family: $gillsans;
		}
	}
}


.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 5px;
  left: 10vw;
  bottom: 0;
  right: 10vw;
  width: 80vw;
  height: calc(80vw * .5625);


  @include media ('<=desktop') {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.transparency-rules {
	width: 65%;
	margin-left: 15%;
	padding-left: 4rem;
	padding-bottom: 3rem;

	@include media ('<=desktop') {
		width:	82%;
		margin-left: 10%;
		padding-left: 0%;
	}

	@include media ('<=tablet') {
		padding-bottom: 10px;
		margin-left: 5%;
	}

	a {
		padding: 5px 25px;
		font-size: 12px;

		@include media ('<=tablet') {
			display: inline-block;
		}

	}

	.custom-heading {
		text-align: left;
		
		.h1 {
			font-size: 30px;
    		word-spacing: -5px;

			@include media ('<=desktop') {
				font-size: 25px;
			}
		}		
	}

	p {
		@include media ('<=desktop') {
			font-size: 15px;
			line-height: 1.2;
		}
	}
}


// Our History page scss ***********************

.intro-history {
	margin-top: -20px;
	background-size: 120% !important;
	#desktop-banner {
		display: block;
	}
	#mobile-banner {
		display: none;
	}
}

.intro-history {
	@include media ('<1190px' ) {
		background-size: 160% !important;
	}
}

.intro-history {
	@include media ('<desktop') {
		background-size: 280% !important;
		#desktop-banner {
			display: none;
		}
		#mobile-banner {
			display: block;
		}
	}
}

#desktop-banner {
	display: block;
}
#mobile-banner {
	display: none;
}

.our-history-page {
	
	.container {
		padding: 0;
		.title {
			text-align: center;
			padding: 0 1em;
			margin-bottom: 1em;
			color: #001c4f;
			font-size: 2em;
			font-weight: normal;
			word-spacing: -10px;
		}
	}

	.our-history {
		padding-left: 0;
		li {
			display: flex;
			min-height: 20em;

			.image-box {
				width: 100%;
				padding-right: 5em;
			}
		}
	}

	.grid {
		display: grid;
		place-items: flex-start;
		grid-template-columns: 300px 15px auto;
		grid-template-rows: repeat(9, [row] fit-content);
		column-gap: 25px;
		row-gap: 10px;
		position: relative;
		.image-box { 
			margin-top: -11px;
			.mobile-size {
				display: none;
			}
			img {
				max-width: 100%;
			}
		}

		.vert {
			display: flex;
			flex-direction: column;
			position: relative;
		}

		.vertDot {
			border: 8px solid #bf9372;
			border-radius: 50%;
			position: absolute;
			top: 143px;
			left: -6px;
		}
		
		.vertLine {
			border: 3px solid #bf9372;
			height: 335px;
			position: absolute;
			top: 143px;
			left: -1px;
		}

		.lastVert {
			border-color: white;
			border-width: 6px;
			left: -1px;
			height: 170px;
		}

		.summary {
			margin-right: 4%;
			margin-top: 27px;
			color: white;
			box-shadow: 0px 0px 20px gray;

			.dateLine {
				background-color: #010149;
				padding: 7% 5% 1% 5%;
				font-family: "big_caslonmedium", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
				font-size: 1.2em;
				font-weight: bold;
				color: #c29878;
				word-spacing: -5px;
			}

			p {
				background-color: #010149;
				margin: 0;
				padding: 0% 11% 7% 5%;

				font-size: .9em;
				line-height: 140%;
				font-weight: 500;
			}
		}
	}

	.grid {
		@include media ('<desktop') {
			.image-box { 
				.mobile-size {
					display: block;
				}
				.desktop-size {
					display: none;
				}
			}

			.vertDot {
				top: 60px;
			}	

			.vertLine {
				top: 60px;
			}

			.summary {
				margin-right: 6%;
				margin-top: 20px;
			}
		}
	}

	.grid {
		@include media ('<tablet') {
			grid-template-columns: 45% 21px auto;
			column-gap: 3px;

			.image-box {
				margin-top: 5px;
			}

			.vertDot {
				border: 4px solid #bf9372;
				border-radius: 50%;
				position: absolute;
				top: 53px;
				left: 2px;
			}
			
			.vertLine {
				border: 1px solid #bf9372;
				height: 510px;
				position: absolute;
				top: 53px;
				left: 5px;
			}

			.lastVert {
				border-color: #fbf8f8;
				border-width: 3px;
				left: 4px;
				height: 250px;
			}

			.summary {
				margin-right: 8%;
				margin-top: 24px;
				.dateLine {
					font-size: .8em;
				}
	
				p {
					font-size: .8em;
				}
			}
		}
	}

	.bottom-text {
		font-family: "big_caslonmedium", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
		text-align: center;
		line-height: 1;
		padding: 0 7em;
		word-spacing: -6px;
		.bt-intro {
			margin-top: 2em;
			color: #001c4f;
			font-size: 2em;
			font-weight: normal;
			min-height: auto;
		}
		.bt-text {
			font-size: 1.5em;
		}
	}

	.bottom-text {
		@include media ('<tablet') {
			padding: 0 3em;
			.bt-intro {
				font-size: 1em;
			}
			.bt-text {
				font-size: .8em;
			}
		}
	}

	.footer-image {
		text-align: center;
		img {
			margin-top: -120px;
			margin-bottom: -100px;
		}
	}

	.footer-image { 
		@include media ('<desktop') {
		img {		
			margin-top: -90px;
			}
		}
	}

	.footer-image { 
		@include media ('<tablet') {
		img {		
			margin-top: -30px;
			margin-bottom: -57px;
			}
		}
	}
	
}






