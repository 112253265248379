/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 4, 2021 */



@font-face {
	font-family: 'aislingregular';
	src: url('../fonts/aisling-regular-webfont.woff2') format('woff2'),
	url('../fonts/aisling-regular-webfont.woff') format('woff'),
	url('../fonts/aisling-regular-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'big_caslonmedium';
	src: url('../fonts/big_caslon_medium-webfont.woff2') format('woff2'),
	url('../fonts/big_caslon_medium-webfont.woff') format('woff'),
	url('../fonts/big_caslon_medium-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'freightsansbook';
	src: url('../fonts/freight_sans_bold-webfont.woff2') format('woff2'),
	url('../fonts/freight_sans_bold-webfont.woff') format('woff'),
	url('../fonts/freight_sans_bold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'freightsansbook';
	src: url('../fonts/freightsans_book-webfont.woff2') format('woff2'),
	url('../fonts/freightsans_book-webfont.woff') format('woff'),
	url('../fonts/freightsans_book-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'freightsansbook';
	src: url('../fonts/freightsans_medium_regular-webfont.woff2') format('woff2'),
	url('../fonts/freightsans_medium_regular-webfont.woff') format('woff'),
	url('../fonts/freightsans_medium_regular-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
    font-family: 'freighttext_medium';
    src: url('../fonts/freight_text_medium-webfont.woff2') format('woff2'),
         url('../fonts/freight_text_medium-webfont.woff') format('woff'),
         url('../fonts/freight_text_medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad-Pro';
    src: url('../fonts/Myriad-Pro-Regular.woff') format('woff'),
         url('../fonts/Myriad-Pro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad-Pro';
    src: url('../fonts/Myriad-Pro-Regular.woff') format('woff'),
         url('../fonts/Myriad-Pro-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad-Pro';
    src: url('../fonts/Myriad-Pro-Regular.woff') format('woff'),
         url('../fonts/Myriad-Pro-Regular.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'gillsans';
    src: url('../fonts/gillsans.woff') format('woff'),
         url('../fonts/gillsans.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'gillsans';
    src: url('../fonts/Gill-Sans-MT-Bold.woff') format('woff'),
         url('../fonts/Gill-Sans-MT-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}